import React from 'react'
import ModalBasic from '../../pages/component/ModalBasic'
import ButtonLoader from '../common/buttonLoader/ButtonLoader'

function SendClarification(props) {
    return (
        <ModalBasic
            id="send-clarification"
            modalOpen={props.sendClarificationModal}
            setModalOpen={props.setOpenModal}
            title="Send Clarification"
        >
            <div className='white-card'>
                {/* <div className='text-2xl text-[#757575] font-bold ml-2 mb-2 text-left'>
                    Send Clarification
                </div> */}
                <hr className='border-[#c9c9c9] mt-2'></hr>
                <div className='text-xl text-[#757575] font-bold px-8 mt-6 mb-6 ml-2'>
                    Comments
                </div>
                <div className='px-10'>
                    <textarea
                        className="w-full h-full"
                        placeholder="Comments"
                        id=""
                        rows="8"
                        type="text"
                        name="clarification"
                        //name={"companyDescription"}
                        // value={props.goGlobalAccountDetails.companyDescription}
                        onChange={(e) => props.onchange(e)}
                        // onChange={(e) => props.handleChangeClarificationDetails(e)}
                        value={props.orgBasicInfo.clarification}
                    // onBlur={() => props.validateField("companyDescription")}
                    /></div>
                <div className="flex items-end justify-center h-full mt-5 mb-5">
                    {props.isLoadingBtn === "Clarification" ? (
                        <ButtonLoader loaderBtnClass = "w-[150px]"/>)
                        :
                        (
                            <button
                                className="btn btn-red text-white"
                                onClick={() => props.saveClarificationDetails()}
                            >
                                <span className="text-xl text-white font-semibold tracking-normal">
                                    Send
                                </span>
                            </button>
                        )
                    }
                </div>
            </div>
        </ModalBasic>
    )
}

export default SendClarification