import React from 'react'
// import { Navigate, Routes } from '../../navigation/NavigationLib'

function BorderSection(props) {
  return (
    <div className="border border-[#c9c9c9] bg-[#fff]">
      <div className="flex justify-between  w-full border-b border-[#c9c9c9]  px-3 py-4">
        <label className="flex items-center">
          <img className="w-7" src={props.img} alt=""></img>
          <span className="small-title 2xl:text-[20px] xl:text-[20px] font-bold text-[#757575] 2xl:pl-3 lg:pl-3 pl-1 pr-8">
            {props.col1Title}
          </span>
        </label>

        {props.col2Title && props.col2Title !== "" ? (
          <div className="flex justify-between title-main-btns">
            {props && props.chapterInfo === true ? (
              <button
                className="btn btn-lightblue 2xl:text-[16px] text-[#ffffff] font-bold "
                onClick={() => props.redirectTo()}
                // onClick={() => Navigate(Routes.OrganisationBasicInformationEdit)}
              >
                {props.colMidTitle}
              </button>
            ) : null}

            <label
              className={props.className}
              onClick={(e) => (props.onClick ? props.onClick(e) : null)}
            >
              {props.col2Title}
            </label>
          </div>
        ) : null}
      </div>
      {props.isForIndividual && props.isForIndividual === true ? (
        <div className="flex justify-between section-top-btn-title w-full border-b border-[#c9c9c9] px-3 py-4 mobile-show individual-btns">
          <div className="flex items-center justify-between flex-wrap">
            <label className="flex items-center">
              <img className="w-5" src={props.img} alt=""></img>
              <span className="small-title 2xl:text-[20px] font-bold text-[#757575] pl-1 whitespace-nowrap">
                {props.col1Title}
              </span>
            </label>

            {props.col2Title && props.col2Title !== "" ? (
              <div className="flex items-center justify-between title-main-btns whitespace-nowrap">
                <label
                  className="text-[15px] text-[#c00000] font-bold mobile-14 pr-2"
                  onClick={(e) => (props.onClick ? props.onClick(e) : null)}
                >
                  {props.col2Title}
                </label>
                <div className="border w-4 h-4 border-[#c00000] p-1 flex items-center justify-center rounded-full">
                  <img className="w-[4px] h-[6px] ml-[1px]" src="/simple-right-arrow.png" alt=""></img>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}

      {props.children}
    </div>
  )
}

export default BorderSection