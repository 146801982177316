import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import SidebarLinkGroup from "./SidebarLinkGroup";


export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? true : storedSidebarExpanded === "false"
  );

  // Set Role
  const [role, setRoleId] = useState(0);

  useEffect(() => {
    let cookie = getAuthProps();
    const roleId = cookie && cookie.roleId ? Number(cookie.roleId) : 0;
    if (roleId > 0) {
      setRoleId(roleId);
    }
  }, []);

  // End Role
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div className="main-sidebar-sections">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-white bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 
        ${sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto 
        lg:translate-x-0 transform h-screen  2xl:sidebar-expanded:!w-80 lg:sidebar-expanded:!w-60 2xl:!w-80 lg:w-[15rem] w-[15rem]  shrink-0 
        bg-white border-r transition-all duration-200 ease-in-out ${sidebarOpen ? "translate-x-0" : "translate-x-0"
          }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between 2xl:p-[15px] lg:p-[15px] p-[7px] border-b">
          {/* Logo */}
          <NavLink end to="/" className="block main-sidebar-logo">
            <img src="/logo.png" alt="" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8 px-4 pt-3 xl:mt-6 lg:mt-6 mt-0  pb-8 overflow-y-scroll lg:overflow-y-auto no-scrollbar">
          {/* Pages group */}
          <div>
            <ul className="mt-3">

              {/* Dashboard Normal Menu Start*/}
              <li
                className={`custom-make-menu rounded-sm mb-[30px] last:mb-0 ${pathname.includes("dashboard") && "bg-white"
                  }`}
              >
                <NavLink
                  end
                  to="Dashboard"
                  className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${pathname.includes("dashboard") && "hover:theme-text-color"
                    }`}
                >
                  <div className="flex menu-icon-img">
                    <svg
                      className="menu-svg"
                      width="24"
                      height="24"
                      viewBox="0 0 513 513"
                      fill={"#8b8b8b"}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1_4)">
                        <path
                          d="M256.009 42.671C256.007 42.671 256.004 42.671 256.002 42.671C256.001 42.671 256.001 42.671 256 42.671C255.999 42.671 255.998 42.671 255.997 42.671C185.326 42.674 121.349 71.308 75.03 117.606C75.014 117.622 74.996 117.635 74.98 117.651C74.963 117.668 74.95 117.686 74.933 117.703C28.688 163.976 0.072 227.867 0.011 298.445C0.011 298.521 0 298.595 0 298.671C0 298.744 0.01 298.814 0.011 298.886C0.061 359.087 20.973 416.125 58.526 461.59C62.579 466.497 68.61 469.338 74.974 469.338H437.022C443.386 469.338 449.417 466.497 453.47 461.59C491.077 416.06 512.009 358.94 512.009 298.671C512.009 157.289 397.391 42.671 256.009 42.671ZM426.68 426.671H85.316C62.035 395.694 47.604 359.01 43.733 320.004H106.667C118.449 320.004 128 310.453 128 298.671C128 286.889 118.449 277.338 106.667 277.338H43.734C47.993 234.433 64.964 195.272 90.825 163.667L105.145 177.987C113.476 186.318 126.984 186.318 135.315 177.987C143.646 169.656 143.646 156.148 135.315 147.817L120.994 133.496C152.599 107.632 191.759 90.659 234.666 86.398V149.339C234.666 161.121 244.217 170.672 255.999 170.672C267.781 170.672 277.332 161.121 277.332 149.339V86.396C320.238 90.655 359.4 107.628 391.008 133.492L376.683 147.817C368.352 156.148 368.352 169.656 376.683 177.987C385.014 186.318 398.522 186.318 406.853 177.987L421.179 163.661C447.046 195.268 464.021 234.432 468.282 277.338H405.332C393.55 277.338 383.999 286.889 383.999 298.671C383.999 310.453 393.55 320.004 405.332 320.004H468.282C464.409 359.001 449.97 395.686 426.68 426.671Z"
                          fill="#8b8b8b"
                        />
                        <path
                          d="M190.102 225.069L277.258 326.545L309.124 280.526L190.102 225.069Z"
                          fill="#8b8b8b"
                        />
                        <path
                          d="M320 362.671H192C180.218 362.671 170.667 372.222 170.667 384.004C170.667 395.786 180.218 405.337 192 405.337H320C331.782 405.337 341.333 395.786 341.333 384.004C341.333 372.222 331.782 362.671 320 362.671Z"
                          fill="#8b8b8b"
                        />
                      </g>
                    </svg>

                    <p className="menu-title text-xl ml-2 font-bold-500 lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Admin Dashboard
                    </p>
                  </div>
                </NavLink>
              </li>
              {/*Dashboard Normal Menu  End*/}
              {/*Account Management Dropdown-Menu Start */}
              <SidebarLinkGroup
                activecondition={
                  pathname === "/" || pathname.includes("Resource")
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/CreateAdminAccount"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }  
                        ${pathname === "/ViewEditAdminAccount"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex menu-icon-img">
                          <svg
                            className="menu-svg"
                            width="24"
                            height="24"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 490 490"
                          >
                            <g>
                              <g>
                                <path
                                  d="M311.5,184.4c0,36.1-29.2,65.3-65.3,65.3s-65.3-29.2-65.3-65.3s29.2-65.3,65.3-65.3S311.5,148.4,311.5,184.4z M245,0
                            C109.9,0,0,109.9,0,245s109.9,245,245,245s245-109.9,245-245S380.1,0,245,0z M357.3,404.7c-8.2-65.7-35.2-133-111.1-133
                            c-76.5,0-103.3,68.3-111.3,134.5C83.5,371,49.7,311.9,49.7,245c0-107.7,87.6-195.3,195.3-195.3S440.3,137.3,440.3,245
                            C440.3,310.9,407.5,369.3,357.3,404.7z"
                                  fill="#8b8b8b"
                                />
                              </g>
                            </g>
                          </svg>
                          <p className="menu-title text-xl ml-2 font-bold-500  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                            Account Management
                          </p>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="CreateAdminAccount"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreateAdminAccount"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Create Account
                              </span>
                            </NavLink>
                          </li>
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="ViewEditAdminAccount"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewEditAdminAccount"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                View/Edit Account
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              {/*Account Management Dropdown-Menu End */}
              {/* Page Management-Menu Start */}
              <SidebarLinkGroup
                activecondition={
                  pathname === "/" || pathname.includes("Resource")
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        // className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150
                        // ${
                        //   (pathname === "/Resource/" ||
                        //     pathname.includes("Resource")) &&
                        //   "hover:theme-text-color"
                        // }`}
                        className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  ${pathname === "/CreatePages"
                          ? "active hover-active-color"
                          : "hover:theme-text-color"
                          }  ${pathname === "/ViewPages"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-basline justify-between">
                          <div className="flex menu-icon-img">
                            <svg
                              className="menu-svg"
                              width="24"
                              height="24"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 324 324"
                            >
                              <g transform="translate(0,324) scale(0.100000,-0.100000)">
                                <path
                                  d="M335 3216 c-48 -22 -77 -60 -82 -110 -7 -55 8 -95 48 -130 l29 -26 1305 0 1305 0 30 33 c55 60 62 127 20 187 -45 64 41 60 -1357 60 -1034 -1 -1274 -3 -1298 -14z"
                                  fill="#6e6e6e"
                                />
                                <path
                                  d="M320 2027 c0 -934 -2 -910 54 -877 34 20 536 481 536 491 0 5 17 20 37 33 20 14 80 68 134 121 85 82 102 95 132 95 41 0 70 -28 65 -64 -2 -16 -102 -134 -297 -348 l-295 -323 1090 -3 c749 -2 1101 0 1126 8 33 9 37 14 43
                                  53 3 23 5 407 3 852 l-3 810 -1312 3 -1313 2 0 -853z m2258 699 c15 -8 38 -26 52 -41 l25 -27 3 -566 c2 -535 1 -569 -16 -601 -36 -67 -132 -91 -199 -50 -65 40 -63 20 -63 640 0 545 1 563 20 594 36 58 119 82 178 51z m-436 -297 c9 -5
                                  30 -26 45 -46 l28 -36 0 -416 c0 -382 -1 -419 -18 -449 -28 -51 -72 -74 -135 -69 -46 3 -59 9 -90 41 l-37 36 -3 422 c-2 296 1 430 9 449 29 72 130 105 201 68z m-474 -209 c18 -11 41 -34 52 -52 19 -31 20 -51 20 -338 0 -287 -1 -307
                                  -20 -338 -62 -102 -204 -94 -251 13 -19 43 -20 64 -17 338 3 259 5 296 21 325 37 68 128 92 195 52z"
                                  fill="#6e6e6e"
                                />
                                <path
                                  d="M725 1398 c-296 -273 -483 -454 -489 -471 -18 -50 51 -107 97 -80 8 4 121 127 253 273 131 146 326 362 434 480 108 118 199 221 204 228 32 53 -83 -46 -499 -430z"
                                  fill="#6e6e6e"
                                />
                                <path
                                  d="M937 1023 c-2 -5 -55 -200 -117 -435 -122 -461 -123 -469 -74 -527 64 -77 196 -56 232 36 6 16 64 226 128 467 64 242 118 446 121 453 4 10 -24 13 -140 13 -80 0 -148 -3 -150 -7z"
                                  fill="#6e6e6e"
                                />
                                <path
                                  d="M2040 1023 c0 -30 246 -925 261 -950 25 -40 93 -68 143 -59 46 9 92 53 105 102 10 37 1 76 -104 473 -63 238 -115 435 -115 437 0 2 -65 4 -145 4 -80 0 -145 -3 -145 -7z"
                                  fill="#6e6e6e"
                                />
                              </g>
                            </svg>
                            <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Page Management
                            </p>
                          </div>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul
                          className={`pl-9 mt-1 ${!open && "hidden"}`}
                        >
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="CreatePages"
                              // className={({ isActive }) =>
                              //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                              //   (isActive ? "!hover-active-color" : "")

                              // }
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CreatePages"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Create Pages
                              </span>
                            </NavLink>
                          </li>
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="ViewPages"
                              // className={({ isActive }) =>
                              //   "block theme-text-color hover:theme-text-color transition duration-150 truncate " +
                              //   (isActive ? "!hover-active-color" : "")
                              // }
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/ViewPages"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                View Pages
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              {/* Page Management-Menu End */}
              {/* Cyber Connect Dropdown-Menu  Start */}
              <SidebarLinkGroup
                activecondition={
                  pathname === "/" || pathname.includes("Resource")
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                          ${pathname === "/CyberConnectAccountApproval"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }  
                          ${pathname === "/ViewCyberConnectAccount"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }  
                          ${pathname === "/CyberConnectContactRequest"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }`
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-basline justify-between">
                          <div className="flex menu-icon-img">
                            <svg className="menu-svg"
                              width="24"
                              height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                              <path id="XMLID_1666_" d="M46,0C20.6,0,0,20.6,0,46s20.6,46,46,46s46-20.6,46-46S71.4,0,46,0z M49.7,83.8c-0.2,0-0.4,0-0.7,0.1V62.2 c5.2-0.1,9.9-0.2,14.2-0.5C59.4,73.4,52.3,81.2,49.7,83.8z M42.3,83.8c-2.7-2.7-9.7-10.5-13.5-22.1c4.2,0.3,9,0.5,14.2,0.5v21.7
																	C42.8,83.9,42.6,83.8,42.3,83.8z M8,46c0-2.5,0.3-5,0.7-7.4c2.2-0.4,6.4-1,12.3-1.6c-0.5,2.9-0.8,5.9-0.8,9.1c0,3.2,0.3,6.2,0.7,9 c-5.8-0.6-10.1-1.2-12.3-1.6C8.3,51,8,48.5,8,46z M26.3,46c0-3.4,0.4-6.6,1-9.6c4.6-0.3,9.8-0.6,15.7-0.6v20.4
																	c-5.8-0.1-11.1-0.3-15.8-0.7C26.7,52.6,26.3,49.4,26.3,46z M49.6,8.2c2.7,2.7,9.6,10.7,13.5,22.1c-4.2-0.3-8.9-0.5-14.1-0.5V8.1 C49.2,8.1,49.4,8.2,49.6,8.2z M43,8.1v21.7c-5.2,0.1-9.9,0.2-14.1,0.5c3.8-11.4,10.8-19.4,13.4-22.1C42.6,8.2,42.8,8.1,43,8.1z
																	M49,56.2V35.8c5.8,0.1,11.1,0.3,15.7,0.6c0.6,3,1,6.2,1,9.6c0,3.4-0.3,6.6-0.9,9.6C60.2,55.9,54.9,56.1,49,56.2z M70.9,37 c5.9,0.6,10.1,1.2,12.3,1.6C83.7,41,84,43.5,84,46c0,2.5-0.3,5-0.7,7.4c-2.2,0.4-6.4,1-12.3,1.6c0.5-2.9,0.7-5.9,0.7-9.1
																	C71.7,42.9,71.4,39.8,70.9,37z M81.4,32.2c-2.8-0.4-6.8-0.9-11.9-1.4c-2.4-8.6-6.6-15.5-10.1-20.4C69.5,14.2,77.5,22.2,81.4,32.2z M32.6,10.4c-3.6,4.8-7.7,11.7-10.1,20.3c-5,0.4-9,1-11.9,1.4C14.5,22.2,22.6,14.2,32.6,10.4z M10.6,59.8c2.8,0.4,6.8,0.9,11.8,1.4
																	c2.4,8.6,6.4,15.5,10,20.3C22.4,77.6,14.5,69.7,10.6,59.8z M59.6,81.5c3.6-4.8,7.6-11.6,10-20.2c5-0.4,9-1,11.8-1.4 C77.5,69.7,69.6,77.6,59.6,81.5z"/>
                            </svg>
                            <p className="menu-title text-xl ml-2 font-medium  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Cyber Connect
                            </p>
                          </div>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul
                          className={`pl-9 mt-1 ${!open && "hidden"}`}
                        >
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="CyberConnectAccountApproval"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/cyberConnect/CyberConnectAccountApproval"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Cyber Connect Account Approval
                              </span>
                            </NavLink>
                          </li>
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="CyberConnectContactRequest"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/cyberConnect/CyberConnectContactRequest"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Cyber Connect Contact Request
                              </span>
                            </NavLink>
                          </li>

                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              {/* Cyber Connect Dropdown-Menu End */}
              {/* Click Analytics Normal Menu Start*/}
              <li
                className={`custom-make-menu rounded-sm mb-[30px] last:mb-0 ${pathname.includes("dashboard") && "bg-white"
                  }`}
              >
                <NavLink
                  end
                  to="ClickAnalytics"
                  className={`block font-bold theme-text-color hover:hover-active-color truncate transition duration-150 ${pathname.includes("ClickAnalytics") && "hover:theme-text-color"
                    }`}
                >
                  <div className="flex menu-icon-img">
                    <svg
                      className="menu-svg"
                      width="24"
                      height="24"
                      version="1.0"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 465.000000 465.000000"
                      preserveAspectRatio="xMidYMid meet">
                      <g transform="translate(0.000000,465.000000) scale(0.100000,-0.100000)" fill="#8b8b8b" stroke="none">
                        <path d="M302 4639 c-169 -21 -279 -127 -289 -278 -4 -59 35 -235 377 -1726
                      413 -1798 393 -1724 483 -1815 73 -74 141 -94 224 -66 76 25 113 55 408 326
                      152 140 281 256 285 258 4 2 307 -286 671 -638 474 -458 678 -649 713 -667 64
                      -32 147 -34 208 -4 28 13 243 212 618 572 509 488 578 558 603 610 33 68 38
                      144 12 205 -11 28 -204 227 -691 714 l-674 676 305 303 c304 302 305 304 325
                      368 51 165 -3 288 -150 344 -56 21 -3265 814 -3325 821 -22 3 -68 2 -103 -3z
                      m1553 -1030 c561 -127 1022 -233 1024 -236 3 -2 -86 -95 -197 -206 -127 -128
                      -207 -216 -216 -239 -24 -56 -20 -108 12 -166 18 -35 235 -267 689 -737 l662
                      -686 -62 -58 c-290 -270 -504 -461 -513 -459 -6 2 -302 305 -658 675 -476 494
                      -658 677 -689 692 -54 26 -128 27 -182 2 -23 -10 -143 -111 -269 -225 -125
                      -115 -229 -206 -231 -204 -4 4 -425 2065 -425 2080 0 5 8 7 18 4 9 -3 476
                      -109 1037 -237z"/>
                      </g>
                    </svg>

                    <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Click Analytics
                    </p>
                  </div>
                </NavLink>
              </li>
              {/*Click Analytics Normal Menu  End*/}
              {/*Field Maintenance Dropdown-Menu Start */}
              <SidebarLinkGroup
                activecondition={
                  pathname === "/" || pathname.includes("Field_Management")
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/DropdownManagement"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }
                        ${pathname === "/EmailTemplateManagement"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }  
                      `}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-basline justify-between">
                          <div className="flex menu-icon-img">
                            <svg
                              className="menu-svg"
                              width="30"
                              height="30"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 100 100"
                            >
                              <g transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)">
                                <path
                                  d="M187 890 c-5 -21 -41 -28 -52 -10 -11 18 -42 -8 -49 -40 -5 -26 -9 -30 -25 -25 -26 8 -48 -29 -31 -51 15 -17 6 -44 -15 -44 -23 0 -19 -47 5 -60 22 -12 27 -36 8 -42 -23 -8 6 -53 33 -53 19 0 25 -6 27 -27 4 -31 35 -46 57
                                  -28 11 9 18 7 34 -9 24 -24 61 -28 61 -7 0 17 35 27 48 14 17 -17 55 1 49 23 -6 21 17 44 36 36 22 -8 39 29 25 54 -11 18 -10 23 10 36 25 18 31 63 7 63 -21 0 -30 27 -16 44 16 19 -7 58 -30 51 -19 -7 -41 17 -34 36 3 8 -5 20 -17
                                  28 -16 10 -23 10 -26 2 -6 -18 -40 -13 -52 9 -6 11 -19 20 -29 20 -10 0 -21 -9 -24 -20z m65 -40 c145 -41 169 -233 37 -301 -52 -26 -126 -22 -167 8 -37 27 -72 91 -72 131 0 82 74 165 155 171 6 1 27 -4 47 -9z"
                                  fill="#8b8b8b"
                                />
                                <path
                                  d="M162 798 c-65 -32 -80 -133 -27 -188 88 -92 236 16 181 132 -28 58 -96 83 -154 56z"
                                  fill="#8b8b8b"
                                />
                                <path
                                  d="M710 851 c0 -25 -40 -35 -56 -14 -11 16 -14 16 -38 -1 -19 -12 -25 -23 -21 -35 7 -22 -19 -46 -40 -37 -9 3 -21 -4 -31 -20 -15 -21 -15 -27 -2 -42 19 -22 12 -52 -13 -52 -27 0 -27 -60 0 -67 26 -7 32 -33 13 -55 -13 -15
                                  -13 -21 2 -42 10 -16 22 -23 31 -20 21 8 47 -15 40 -36 -4 -12 4 -23 21 -35 25 -16 28 -16 39 -2 15 21 55 11 55 -14 0 -28 60 -27 67 0 6 25 33 32 55 13 14 -12 20 -11 42 3 14 10 23 22 19 31 -7 20 17 46 38 41 12 -3 24 4 34 20 14
                                  21 15 27 3 41 -19 22 -12 49 13 55 27 7 28 67 0 67 -24 0 -36 41 -17 55 22 16 -7 65 -33 58 -27 -7 -52 18 -40 39 6 12 2 20 -17 33 -22 14 -28 15 -42 3 -22 -19 -49 -12 -55 13 -7 27 -67 28 -67 0z m119 -66 c146 -74 145 -272 -2 -341
                                  -65 -30 -110 -30 -167 -1 -146 73 -143 281 5 348 55 25 107 24 164 -6z"
                                  fill="#8b8b8b"
                                />
                                <path
                                  d="M706 750 c-110 -34 -130 -191 -31 -256 37 -24 106 -22 143 4 34 25 62 77 62 117 0 88 -92 160 -174 135z"
                                  fill="#8b8b8b"
                                />
                                <path
                                  d="M334 389 c-3 -6 -16 -8 -28 -5 -23 6 -55 -9 -43 -20 9 -9 -12 -36 -23 -29 -4 3 -13 -2 -20 -10 -10 -12 -10 -18 0 -30 10 -12 9 -16 -4 -21 -20 -8 -21 -38 -1 -54 12 -10 13 -16 4 -26 -9 -11 -8 -18 1 -29 7 -9 15 -13 18
                                  -10 7 7 33 -16 27 -25 -3 -4 2 -13 10 -20 12 -10 18 -10 29 0 12 9 17 7 28 -9 17 -27 32 -27 51 1 12 18 18 20 25 10 11 -16 45 -4 38 14 -6 15 17 37 28 27 12 -12 26 20 20 44 -4 16 -1 25 10 29 21 8 21 33 0 41 -10 4 -14 13 -10 27 6
                                  23 -9 55 -20 43 -10 -10 -35 13 -28 25 9 15 -17 28 -43 21 -14 -3 -23 -1 -23 6 0 14 -37 14 -46 0z m75 -37 c35 -19 71 -72 71 -105 0 -41 -32 -86 -76 -107 -38 -19 -49 -20 -79 -10 -50 16 -85 62 -85 111 0 53 22 92 62 112 43 21 66 21 107 -1z"
                                  fill="#8b8b8b"
                                />
                                <path
                                  d="M295 298 c-31 -37 -29 -77 7 -115 61 -65 163 13 128 98 -25 59 -92 68 -135 17z"
                                  fill="#8b8b8b"
                                />
                              </g>
                            </svg>
                            <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Field Maintenance
                            </p>
                          </div>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="/DropdownManagement"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/DropdownManagement"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Dropdown Management
                              </span>
                            </NavLink>
                          </li>
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="/EmailTemplateManagement"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/EmailTemplateManagement"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Email Template Management
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              {/*Field Maintenance Dropdown-Menu End */}
              {/*Report-Menu Start */}
              <SidebarLinkGroup
                activecondition={
                  pathname === "/" || pathname.includes("Financial")
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={` block font-bold theme-text-color hover:hover-active-color truncate transition duration-150  
                        ${pathname === "/CyberConnectMemberReport"
                            ? "active hover-active-color"
                            : "hover:theme-text-color"
                          }
                        `}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-basline justify-between">
                          <div className="flex menu-icon-img">

                            <svg
                              className="menu-svg"
                              width="24"
                              height="24"
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"

                              viewBox="0 0 489.000000 489.000000"
                              preserveAspectRatio="xMidYMid meet">

                              <g transform="translate(0.000000,489.000000) scale(0.100000,-0.100000)"
                                fill="#8b8b8b" stroke="none">
                                <path d="M2415 4879 c-71 -3 -155 -7 -186 -8 l-57 -1 -31 -172 c-18 -95 -35
																-179 -40 -187 -4 -8 -45 -22 -95 -32 -71 -14 -273 -74 -322 -95 -7 -3 -60 61
																-119 141 -59 80 -111 145 -117 145 -10 0 -347 -186 -360 -198 -4 -4 15 -88 43
																-187 l50 -180 -23 -18 c-83 -66 -197 -169 -238 -215 l-48 -54 -172 77 c-95 42
																-176 72 -180 68 -4 -4 -60 -87 -124 -183 l-116 -175 135 -135 134 -135 -33
																-75 c-36 -81 -86 -227 -111 -324 -8 -32 -16 -60 -17 -61 -2 -2 -84 -11 -183
																-20 -99 -10 -183 -20 -186 -24 -4 -3 -10 -101 -14 -217 l-7 -210 174 -45 173
																-45 3 -39 c5 -80 34 -262 53 -337 10 -42 19 -83 19 -91 0 -8 -67 -53 -152
																-102 -84 -48 -154 -89 -156 -90 -3 -3 158 -378 168 -388 4 -4 88 6 187 23 100
																16 183 30 186 30 2 0 24 -30 49 -67 25 -38 73 -102 106 -145 l62 -76 -100
																-145 c-55 -80 -100 -147 -100 -150 0 -3 72 -69 159 -146 l160 -141 153 106
																153 107 55 -32 c30 -18 116 -62 190 -98 74 -36 136 -67 138 -69 2 -1 -6 -74
																-17 -161 -12 -87 -21 -169 -21 -183 0 -24 5 -26 208 -72 114 -25 208 -43 210
																-40 1 4 40 78 86 165 l83 157 239 2 239 1 69 -151 c38 -84 74 -152 80 -152 6
																0 97 24 201 53 l190 53 0 171 0 172 120 59 c66 33 149 78 185 100 54 33 68 38
																80 27 107 -90 264 -206 273 -202 21 9 308 278 303 285 -2 4 -40 71 -85 149
																-44 78 -81 145 -81 148 0 4 29 43 64 88 35 45 86 116 112 158 l49 76 160 -38
																c88 -21 165 -39 171 -39 11 0 164 365 164 390 0 5 -61 53 -135 106 -74 53
																-137 99 -139 102 -2 4 7 58 20 120 12 62 28 164 34 225 6 61 12 113 14 114 1
																2 76 19 167 40 90 20 165 37 166 37 4 2 -35 417 -40 422 -3 3 -83 18 -178 32
																-165 26 -173 28 -180 52 -40 140 -77 248 -111 324 -22 50 -42 96 -44 102 -3 6
																56 64 131 128 75 65 135 121 133 126 -10 25 -235 339 -243 340 -6 0 -84 -27
																-174 -59 l-164 -60 -104 99 c-57 54 -141 127 -186 161 -45 34 -81 67 -79 73 2
																6 30 84 64 174 33 90 59 165 57 166 -2 2 -88 46 -190 99 l-186 97 -119 -136
																c-65 -75 -122 -141 -125 -146 -4 -6 -39 0 -85 15 -98 31 -207 55 -316 71 l-83
																11 -16 90 c-9 50 -24 134 -34 188 -16 95 -17 97 -43 95 -15 -1 -85 -5 -157 -9z
																m368 -818 c694 -151 1207 -694 1318 -1396 21 -129 16 -389 -9 -527 -64 -346
																-216 -633 -466 -883 -139 -139 -245 -218 -406 -303 -614 -324 -1380 -225
																-1894 247 -434 397 -627 985 -511 1555 139 688 683 1207 1384 1321 130 21 456
																13 584 -14z"/>
                                <path d="M2284 3416 c-228 -44 -407 -139 -555 -293 -120 -126 -202 -269 -251
																-438 -20 -71 -23 -101 -22 -260 0 -167 2 -187 27 -270 108 -356 360 -598 727
																-701 52 -15 101 -19 240 -19 191 0 252 11 402 75 295 126 523 413 584 735 24
																131 15 328 -20 450 -103 352 -368 612 -714 701 -120 32 -311 41 -418 20z"/>
                              </g>
                            </svg>




                            <p className="menu-title text-xl ml-2 font-bold  lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Report
                            </p>
                          </div>
                        </div>
                      </a>
                      <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                          <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="/CyberConnectMemberReport"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CyberConnectMemberReport"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Extract Report
                              </span>
                            </NavLink>
                          </li>
                          {/* <li className="my-4 last:mb-0">
                            <NavLink
                              end
                              to="/CyberConnectNotificationUpdateReport"
                              className={`block theme-text-color hover:theme-text-color transition duration-150 truncate  ${pathname === "/CyberConnectNotificationUpdateReport"
                                ? "hover-active-color"
                                : null
                                }`}
                            >
                              <span className="sub-menu-title font-medium lg:opacity-1 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Cyber Connect Notification Update Report
                              </span>
                            </NavLink>
                          </li> */}
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>
              {/*Report-Menu End */}

            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 2xl:hidden lg:hidden hidden sm:inline-flex justify-end mt-auto">
          <div className="py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="theme-text-color"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
