import React from 'react'

export default function ActionDeleteButton(props) {
  return (
    <button className='action-btn right-icon' id={props.index}
      onClick={(e) => props.onActionClick(props.value, props.index, e, props.value2)}
    >
      <svg className="menu-svg" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 135">
        <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)">
          <path d="M504 1336 c-32 -14 -64 -67 -64 -105 0 -20 -6 -21 -147 -23 -124 -3 -148 -5 -148 -18 0 -10 12 -16 32 -18 28 -3 33 -7 33 -30 0 -72 41 -1021 46 -1049 3 -19 17 -42 35 -57 l31 -26 354 0 c386 0 398 2 422 56 9 18 19 196 33
                      543 11 284 23 526 25 539 3 17 11 22 34 22 23 0 30 4 30 20 0 19 -7 20 -168 20 l-168 0 -11 43 c-7 23 -24 53 -38 67 -25 24 -33 25 -163 27 -100 2 -145 -1 -168 -11z m305 -43 c11 -10 24 -33 27 -50 l7 -33 -186 0 -186 0 10 33 c17 60
                      36 67 179 67 107 0 131 -3 149 -17z m305 -225 c-3 -57 -14 -300 -23 -541 -16 -390 -20 -440 -36 -457 -17 -19 -32 -20 -376 -20 l-359 0 -14 23 c-12 18 -19 120 -36 497 -11 261 -20 503 -20 538 l0 62 435 0 435 0 -6 -102z" fill="#000000" />
          <path d="M436 964 c-10 -25 -7 -747 3 -763 27 -42 31 8 31 390 0 326 -2 389 -14 389 -8 0 -17 -7 -20 -16z" fill="#000000" />
          <path d="M665 968 c-3 -7 -4 -184 -3 -393 3 -330 5 -380 18 -380 13 0 15 50 15 389 0 303 -3 390 -12 393 -7 3 -15 -1 -18 -9z" fill="#000000" />
          <path d="M895 968 c-3 -7 -4 -184 -3 -393 3 -330 5 -380 18 -380 13 0 15 50 15 389 0 303 -3 390 -12 393 -7 3 -15 -1 -18 -9z" fill="#000000" />
        </g>
      </svg>
    </button>
  )
}
