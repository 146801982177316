import Dashboard from "../pages/dashboard/Dashboard";
// import EmailTemplateManagement from "../pages/fieldManagement/EmailTemplateManagement";
import CreateEmailTemplate from "../components/fieldManagement/CreateEmailTemplate";
import PageNotFound from "../pages/pageNotFound/PageNotFound";
// import CyberConnectRequest from "../components/accountManagement/createAdminAccount/CyberConnectRequest";
import ClickAnalytics from "../pages/clickAnalytics/ClickAnalytics"
import CyberConnectMemberReport from "../pages/report/CyberConnectMemberReport";
import CyberConnectAccountApproval from  '../pages/cyberConnect/CyberConnectAccountApproval';
import CyberConnectContactRequest from "../pages/cyberConnect/CyberConnectContactRequest";
import CreateAdminAccount from "../pages/accountManagement/CreateAdminAccount";
import ViewEditAdminAccount from "../pages/accountManagement/ViewEditAdminAccount";
import ViewPages from "../pages/pagesmanagement/ViewPages";
import DropdownManagement from "../pages/fieldManagement/DropdownManagement";
import EmailTemplateManagement from "../pages/fieldManagement/EmailTemplateManagement";
import CreatePages from "../pages/pagesmanagement/CreatePages";
import ViewCyberConnectAccount from "../pages/cyberConnect/ViewCyberConnectAccount";
import EditPages from "../pages/pagesmanagement/EditPages";


const ComponentNavigation = [

  {
    path: "/",
    title: "Dashboard",
    component: Dashboard,
    hasParams: false,
    roles: [1, 2, 3, 4, 5, 6, 7, 8]
  },
  {
    path: "Dashboard",
    previousPath : "Dashboard",
    title: "Dashboard",
    component: Dashboard, 
    hasParams: false,
    roles: [1, 2, 3, 4, 5, 6, 7, 8]
  },
  {
    path: "PageNotFound",
    title: "PageNotFound",
    component: PageNotFound,
    hasParams: false,
    roles: [1, 2, 3, 4, 5, 6, 7, 8]
  },

  
  // {
  //   path: "EmailTemplateManagement",
  //   previousPath: "Field Management",
  //   title: "Email Template Management",
  //   component: EmailTemplateManagement,
  //   roles: [1, 8],
  //   hasParams: false,
  // },
  

  //Cyber Connect Account Approval

  {
    path: "CyberConnectAccountApproval",
    previousPath: "Cyber Connect",
    title: "Cyber Connect Account Approval",
    component: CyberConnectAccountApproval,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "CyberConnectContactRequest",
    previousPath: "Cyber Connect",
    title: "Cyber Connect Contact Request",
    component: CyberConnectContactRequest,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "ViewCyberConnectAccount",
    previousPath:"Cyber Connect ",
    title: "View Cyber Connect Account",
    component: ViewCyberConnectAccount,
    roles: [1, 8],
    hasParams: false,
  },
 
  {
    path: "ClickAnalytics",
    previousPath: "Click Analytics",
    title: "Click Analytics",
    component: ClickAnalytics,
    roles: [1, 8],
    hasParams: false,
  },


//Account Management Start

{
  path: "CreateAdminAccount",
  previousPath: "Account Management",
  title: "Create Admin Account",
  component: CreateAdminAccount,
  roles: [1, 8],
  hasParams: false,
},
{
  path: "CreateAdminAccount/adminId",
  title: "Edit Admin Account",
  component: CreateAdminAccount,
  roles: [1, 8],
  hasParams: false,
},
{
  path: "ViewEditAdminAccount",
  previousPath: "Account Management",
  title: "View/Edit Admin Account",
  component: ViewEditAdminAccount,
  roles: [1, 8],
  hasParams: false,
},

//Account Management End

//pages MAnagemwnt Start
{
  path: "CreatePages",
  previousPath: "Pages Management",
  title: "Pages Management",
  component: CreatePages,
  hasParams: false,
  roles: [1, 7, 2, 9, 10, 11, 12],
},
{
  path: "CreatePages/:MainMenuId/:SubMenuId/:PageNameId",
  title: "Edit Page Details",
  component: CreatePages,
  hasParams: true,
  roles: [1, 7, 2, 9, 10, 11, 12]
},
{
  path: "ViewPages",
  previousPath: "Pages Management",
  title: "View Pages",
  component: ViewPages,
  hasParams: false,
  roles: [1, 7, 2, 9, 10, 11, 12],
},
{
  path: "EditPages/:PagesDetailsId",
  title: "Edit Page Details",
  component: EditPages,
  hasParams: true,
  roles: [1, 7, 2, 9, 10, 11, 12],
},

//pages MAnagemwnt end

//Field Management Start

{
  path: "DropdownManagement",
  previousPath: "Field Management",
  title: "Dropdown Management",
  component: DropdownManagement,
  roles: [1, 8],
  hasParams: false,
},

// {
//   path: "CreateEmailTemplate",
//   previousPath: "Field Management",
//   title: "Create Email Template",
//   component: CreateEmailTemplate,
//   roles: [1, 8, 2, 10, 7, 9, 12],
//   hasParams: false,
// },
{
  path: "CreateEmailTemplate",
  previousPath: "Field Management",
  title: "Create Email Template",
  component: CreateEmailTemplate,
  roles: [1],
  hasParams: false,
},
{
  path: "EmailTemplateManagement",
  previousPath: "Field Management",
  title: "Email Template Management",
  component: EmailTemplateManagement,
  roles: [1],
  hasParams: false,
},
{
  path: "CreateEmailTemplate/:emailTemplateId",
  previousPath: "Field Management",
  title: "Create Email Template",
  component: CreateEmailTemplate,
  roles: [1],
  hasParams: true,
},


//Field Management End

//Report Start

{
  path: "CyberConnectMemberReport",
  previousPath: "Report",
  title: "Cyber Connect Member Report",
  component: CyberConnectMemberReport,
  roles: [1, 8],
  hasParams: false,
},

//Report End

];
export default ComponentNavigation;