import React from 'react'

const Footer = () => {
  return (
    <div className='footer-bg 2xl:py-8 lg:py-[1rem]'>
      <p className='text-white text-center 2xl:text-xl lg:text-[14px] flex items-center justify-center'>
        <span>© SGTECH ALL RIGHTS RESERVED</span> <span className='px-3'>|</span> <span>POWERED BY FLYING CAPE</span>
      </p>
    </div>
  )
}

export default Footer