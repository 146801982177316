import React, { Component } from 'react'
import Table from '../../components/table/Table';
import SwalServices from '../../services/swalServices/SwalServices';
import ClickAnalyticsServices from '../../services/axiosServices/apiServices/ClickAnalyticsServices';
import moment from 'moment';


export default class ClickAnalytics extends Component {
    constructor(props) {
        super(props);
        this.swalServices = new SwalServices();
        this.clickAnalyticsServices = new ClickAnalyticsServices();
        this.state = {
            companyDescriptionModal: false,
            solutionCategoryModel: false,
            connectNowModel: false,
            totalResultes: 0,
            clickAnalyticsList: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                dateFrom: null,
            },
            searchText: "",
            isLoading: false
        }
    }

    actions = (element, index, value) => {
        return element !== "Action" ? null : (
            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt break-all text-center">
                <a className='text-[#7a98ad] underline underline-offset-auto' href="">Download</a>
            </td>
        );
    };

    getClickAnalyticsList = () => {
        let pagination = { ...this.state.pagination }
        this.setState({ isLoading: true });
        this.clickAnalyticsServices.getClickAnalyticsList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
                details.map((key, index) => {
                    key.dateOfClick = key.dateOfClick ? moment(key.dateOfClick).format("DD MMM YYYY") : '-';
                    key.totalNumberOfClick = key.totalNumberOfClick ? key.totalNumberOfClick : '-';
                });
                this.setState({ clickAnalyticsList: details });
                this.setState({
                    totalResultes: response.responseItem.responseContent.totalCount,
                });
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    componentDidMount() {
        this.getClickAnalyticsList();
    }

    search = (event) => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        detail.pageSize = 10;
        this.setState({ pagination: { ...detail } });
        this.getClickAnalyticsList();
    }

    handleSearchTextChange = (event, identity) => {
        const value = event.target.value;
        this.setState({ searchText: value });
    };

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ pagination: { ...detail } });
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getClickAnalyticsList();
    };

    render() {
        return (
            <>
                <div className="main-body-section">
                    <div className="custom-card shadow-lg">
                        <div className="filter-info grid grid-cols-12 gap-6">
                            <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                                <label
                                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                    for="default"
                                >
                                    Company Name
                                </label>
                                <input
                                    id="searchText"
                                    name="searchText"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </div>
                            <div className="xl:col-span-3 lg:col-span-3 col-span-12">
                                <label
                                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                    for="default"
                                >
                                    Date
                                </label>
                                <input
                                    id="default"
                                    name="dateFrom"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(
                                        this.state.pagination.dateFrom
                                    ).format("yyyy-MM-DD")}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </div>
                            <div className="xl:col-span-5 lg:col-span-3 col-span-12">
                                <div className="flex items-end justify-end h-full pb-1">
                                    <button
                                        className="btn btn-red text-white"
                                        onClick={(e) => this.search(e)}
                                    >
                                        <span className="text-xl text-white font-semibold tracking-normal">
                                            Search
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cyber-connect-account-table left-table mt-10">
                        <Table
                            columns={[
                                { name: "companyName", title: "Company Name" },
                                { name: "totalNumberOfClick", title: "Total Number of Clicks" },
                                { name: "dateofClick", title: "Date of Clicks" },
                            ]}
                            rows={this.state.clickAnalyticsList}
                            isLoading={this.state.isLoading}
                            sortingColumns={[""]}
                            pagination={this.state.pagination}
                            totalResultes={this.state.totalResultes}
                            totalCount={this.state.totalResultes}
                            setPagination={this.setPagination.bind(this)}
                        />
                    </div>
                </div>
            </>
        )
    }
}
