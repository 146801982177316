import React from 'react'
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import PageLoader from '../../components/common/pageLoader/PageLoader';
import MultiSelectDropdown from '../../components/dropdown/MultiSelectDropdown';
import ValidationText from "../../utils/validation/ValidationText"

function OrganisationBasicInformation(props) {
    return (
        <>
            {props.isLoading ? <PageLoader /> :
                <div className="w-full 2xl:px-8 lg:px-4 px-4 mt-5">
                    <div className="grid grid-cols-12 lg:gap-6 gap-0 w-full ">
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2 disabled-input">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    Company UEN/Business Registration Number
                                    <span className="text-[#C00000]">*</span>
                                </h2>
                                <input
                                    disabled
                                    className='outline-none appearance-none w-full custom-input'
                                    type="text"
                                    name={"businessRegistrationNumber"}
                                    value={props.value.businessRegistrationNumber}
                                    onChange={(e) => props.onchange(e)}
                                    onBlur={() => props.onblur("businessRegistrationNumber")}
                                ></input>
                                <ValidationText error={props.validationMessages.error.businessRegistrationNumber} />
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    Company Name<span className="text-[#C00000]">*</span>
                                </h2>
                                <input
                                    className='outline-none appearance-none w-full custom-input'
                                    type="text"
                                    name={"companyName"}
                                    value={props.value.companyName}
                                    onChange={(e) => props.onchange(e)}
                                    onBlur={() => props.onblur("companyName")}
                                ></input>
                                <ValidationText error={props.validationMessages.error.companyName} />
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    Industry Served<span className="text-[#C00000]">*</span>
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="industryServed"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={props.industryServed}
                                    onChange={props.onDropdownChange}
                                    allowSelectAll={true}
                                    value={props.value.selectIndustryServed}
                                />
                                <ValidationText error={props.validationMessages.error.selectIndustryServed} />
                            </div>
                            {props.isIndustryOthers === true ?
                                <div className="flield-sec mt-2">
                                    <label className="text-[#848484] big-bullet-points font-bold mb-2 inline-block mobile-14">
                                        Others
                                    </label>
                                    <input
                                        className='w-full custom-input mt-5'
                                        type="text"
                                        name={"IndustryOthers"}
                                        value={props.value.IndustryOthers}
                                        onChange={(e) => props.onchange(e)}
                                    ></input>
                                    {/* <ValidationText error={props.validationMessages.error.IndustryOthers} /> */}
                                </div>
                                : null}
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    Representative First Name
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className="w-full outline-none appearance-none">
                                    <input
                                        className='outline-none appearance-none w-full custom-input'
                                        type="text"
                                        name={"representativeFirstName"}
                                        value={props.value.representativeFirstName}
                                        onChange={(e) => props.onchange(e)}
                                        onBlur={() => props.onblur("representativeFirstName")}
                                    ></input>
                                    <ValidationText error={props.validationMessages.error.representativeFirstName} />
                                </div>
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    Representative Last Name
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className="w-full outline-none appearance-none">
                                    <input
                                        className='outline-none appearance-none w-full custom-input'
                                        type="text"
                                        name={"representativeLastName"}
                                        value={props.value.representativeLastName}
                                        onChange={(e) => props.onchange(e)}
                                        onBlur={() => props.onblur("representativeLastName")}
                                    ></input>
                                    <ValidationText error={props.validationMessages.error.representativeLastName} />
                                </div>
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    Designation<span className="text-[#C00000]">*</span>
                                </h2>
                                <input
                                    className='outline-none appearance-none w-full custom-input'
                                    type="text"
                                    name={"designation"}
                                    value={props.value.designation}
                                    onChange={(e) => props.onchange(e)}
                                    onBlur={() => props.onblur("designation")}
                                ></input>
                                <ValidationText error={props.validationMessages.error.designation} />
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    Business Email<span className="text-[#C00000]">*</span>
                                </h2>
                                <input
                                    className='outline-none appearance-none w-full custom-input'
                                    type="text"
                                    name={"businessEmail"}
                                    value={props.value.businessEmail}
                                    onChange={(e) => props.onchange(e)}
                                    onBlur={() => props.onblur("businessEmail")}
                                ></input>
                                <ValidationText error={props.validationMessages.error.businessEmail} />
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    Business Contact Number
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <input
                                    className='outline-none appearance-none w-full custom-input'
                                    type="text"
                                    name={"businessContactNumber"}
                                    value={props.value.businessContactNumber}
                                    onChange={(e) => props.onchange(e)}
                                    onBlur={() => props.onblur("businessContactNumber")}
                                ></input>
                                <ValidationText error={props.validationMessages.error.businessContactNumber} />
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-2 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <h2 className="text-xl text-[#757575] font-bold mb-2">
                                    SSIC Code
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="SSICCodedata"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={props.SSICCodedata}
                                    onChange={props.onDropdownChange}
                                    allowSelectAll={true}
                                    value={props.value.selectSSICCode}
                                />
                            </div>
                            <ValidationText error={props.validationMessages.error.selectSSICCode} />
                        </div>
                    </div>
                    <div className="grid grid-cols-12 lg:gap-6 gap-0 pb-10">
                        <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                            <a
                                className="flex items-center lg:justify-end justify-center"
                            >
                                {props.isBtnLoading === "basicInfo" ?
                                    <ButtonLoader loaderBtnClass="w-[200px]" /> :
                                    <button
                                        className='btn btn-lightblue text-white font-bold whitespace-nowrap 2xl:text-lg lg:text-base w-[200px]'
                                        onClick={() => props.updateDashboardOrgBasicInfo()}
                                    // disabled={this.state.accountDetails.termsAndCondition === true ? false : true}
                                    >
                                        Update
                                    </button>
                                }
                            </a>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default OrganisationBasicInformation

