import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { decryptionAPI } from "../../utils/Encryption";
const API_URL = "http://localhost:5099/";
const UPLOAD_ENDPOINT = "api/Common/SaveFileToAmazon";

export default function MyEditor({ handleChange, ...props }) {
    const custom_config = {
        extraPlugins: [ uploadPlugin ],
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'blockQuote',
            'insertTable',
            '|',
            // 'imageUpload',
            'undo',
            'redo'
          ]
        },
        table: {
          contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
        }
      }
      

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("filename",file.filename);
             
            var config = {headers: { 'content-type': 'multipart/form-data' },};
              
              axios.post(`${API_URL}/${UPLOAD_ENDPOINT}`, body, config)
              .then(response => {
                response = decryptionAPI(response.data);
                resolve({
                    default: `${API_URL}/${response.filePath}`
                });
              })
              .catch(error => {
              }
              );

          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="App">
      <CKEditor
        key={(props && props.key) ? props.key : 'ckEditor'}
        config={custom_config}
        editor={ClassicEditor}
        onReady={(editor) => {}}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
        onChange={(event, editor) => {
          handleChange(editor.getData());
        }}
        {...props}
      />
    </div>
  );
}
