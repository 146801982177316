import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import LogoImage from "../../images/logo.png";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { setAuthProps } from "../../utils/AuthenticationLibrary";
import { ErrorMessage } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import { MsalContext } from "@azure/msal-react";
import { withMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../HOC/authConfig";
import { InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
const msalInstance = new PublicClientApplication(msalConfig);

class Login extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.authServices = new AuthenticationServices();
    this.swalServices = new SwalServices();

    this.state = {
      isLoading: false,
      redirect: "",
      userDetails: {
        userName: "",
        password: "",
      },
      validationRules: {
        userName: [
          {
            type: "require",
            message: ErrorMessage.FieldRequired.replace("{0}", "Email address"),
          },
          {
            type: "email",
            message: ErrorMessage.NOTVALIDPATTERN.replace(
              "{0}",
              "Email address"
            ),
          },
        ],
        password: [
          {
            type: "require",
            message: ErrorMessage.FieldRequired.replace("{0}", "Password"),
          },
          //   { type: 'password', message: ErrorMessage.PasswordNotValid }
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.userDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.userDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  redirectToDashboard = () => {
    this.setState({ route: "/Dashboard" });
  };

  login = () => {
    if (this.isValidateAllFields()) {
      this.setState({ isLoading: true });
      this.authServices.adminLogin(this.state.userDetails).then((response) => {
        if (response.statusCode === 200 && response.responseItem) {
          let authDetails = response.responseItem.responseContent;
          setAuthProps(authDetails);
          window.location.href = "/Dashboard";
        } else {
          this.swalServices.Error(ErrorMessage.InvalidAuthorization);
        }
        this.setState({ isLoading: false });
      });
    }
  };

  // This is Temp login method remove this after dynamic login
  // login = () => {

  //   let authData = {
  //     adminUserId: 1,
  //     isAuthenticated: true,
  //     isSuperAdmin: null,
  //     message: null,
  //     name: "Admin",
  //     roleId: 1,
  //     sessionTimeout: 500,
  //     token: { token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZGVudGlma…3MjN9.J0ity0BgaRPtyPj6i7Mq3l9jta7SFCTv5RldQsi34C8', expires: '2023-04-30T05:15:23.8426093Z' },
  //     userDisplayName: null,
  //     userName: "admin@gmail.com",
  //   }
  //   setAuthProps(authData);
  //   // this.setState({ route: "/Dashboard" });
  //   window.location.href = "/Dashboard";
  // };

  handleLogin(loginType) {
    this.setState({ isLoading: true });
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
      msalInstance.loginPopup(loginRequest).then(response => {
        var azureusername = response.account.username;
        this.authServices.adminLogin(azureusername).then((response) => {
          if (response.statusCode === 200 && response.responseItem) {
            let authDetails = response.responseItem.responseContent;
            setAuthProps(authDetails);
            window.location.href = "/Dashboard";
          }
          else {
            this.swalServices.Error(ErrorMessage.InvalidAuthorization);
          }
        });
      })
    }
  }

  handleChange = (key, value) => {
    let newUserDetails = { ...this.state.userDetails };
    newUserDetails[key] = value;
    this.setState({ userDetails: newUserDetails });
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div>
        <div className="main-login-sec">
          <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-white w-full rounded-sm">
            <div className="login-main-bg max-w-md w-full space-y-8 px-8 p-10 shadow-red">
              <div className="">
                <img
                  className="mx-auto w-[220px]"
                  src={LogoImage}
                  alt="logo"
                />
                <h2 className="mt-5 text-center text-3xl font-extrabold text-[#909090]">
                  Log in
                </h2>
              </div>
              <input type="hidden" name="remember" value="true" />
              <div className="rounded-md -space-y-px relative">
                <div>
                  <label for="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="login-input appearance-none rounded relative block w-full px-3 py-2 border border-[#c00000] placeholder-[#757575] text-[#c00000] focus:outline-none focus:ring-[#c00000] focus:border-[#c00000] focus:z-10 sm:text-sm bg-white"
                    placeholder="Email address"
                    value={this.state.userDetails.userName}
                    onChange={(event) =>
                      this.handleChange("userName", event.target.value)
                    }
                    onBlur={() => this.validateField("userName")}
                  />
                  <ValidationText
                    className="absolute left-0 top-[100%]"
                    error={this.state.validState.error.userName}
                  />
                </div>

                <div className="mt-4">
                  <label for="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className=" login-input appearance-none rounded relative block w-full px-3 py-2 border border-[#c00000] placeholder-[#757575] text-[#c00000] focus:outline-none focus:ring-[#c00000] focus:border-[#c00000] focus:z-10 sm:text-sm bg-white"
                    placeholder="Password"
                    value={this.state.userDetails.password}
                    onChange={(event) =>
                      this.handleChange("password", event.target.value)
                    }
                    onBlur={() => this.validateField("password")}
                  />
                  <ValidationText
                    className="absolute left-0 top-[100%]"
                    error={this.state.validState.error.password}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center register-desc">
                  <input
                    className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                    id="emember-me"
                    type="checkbox"
                    name="remember-me"
                  />
                  <label
                    for="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    {" "}
                    Remember me{" "}
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="/ForgetPassword"
                    className="font-medium text-[#005b9c] hover:text-[#004270]"
                  >
                    {" "}
                    Forgot your password?{" "}
                  </a>
                </div>
              </div>

              <div className="flex justify-center">
                {this.state.isLoading ? (
                  <ButtonLoader />
                ) : (
                  <button
                    type="submit"
                    className="group relative flex items-center justify-center text-center border border-transparent text-sm font-medium text-white btn btn-red focus:outline-none md:max-w-max"
                    onClick={() => this.login()}
                  >
                    <span className="absolute left-0 flex items-center pl-8">
                      <svg
                        className="h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    <span className="ml:text-[16px] pl-[20px] pt-1">
                      Sign in
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login = withMsal(Login);
