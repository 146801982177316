import React from "react";
import UserMenu from "../../components/profile/Profile";
import { signOut } from "../../utils/AuthenticationLibrary";

const Header = ({ sidebarOpen, setSidebarOpen }) => {

  return (
    <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between -mb-px header-area-height-set">
          {/* Header: Left side */}
          <div className="flex"></div>

          {/* Header: Right side */}
          <div className="flex items-center">
            <UserMenu align="right" />
            <hr className="w-px 2xl:h-[89px] lg:h-[70px] h-[60px] bg-slate-200 m-0" />
            <a href="#/" className="text-[#000000] text-xl w-8 h-8 inline-block 2xl:mr-10 lg:mr-6 "
              aria-controls="search-modal" onClick={() => signOut()}
            >
              <img
                className="menu-svg  2xl:mr-10 lg:ml-6  mr-7 w-[35px] h-[25px] fill-[#757575]"
                src="/logout-icon.svg"
                alt=""
              ></img>
            </a>
            {/*  Divider */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
