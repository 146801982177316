import React from "react";
import ModalBasic from "../../pages/component/FormModal";
import DropdownSelect from "../dropdown/Dropdown";
import ValidationText from "../../utils/validation/ValidationText";

const AddNewPageNameForSubModule = (props) => {
  return (
    <ModalBasic
      id="add-edit-strategic-member-modal"
      modalOpen={props.setAddPageNameModel}
      setModalOpen={props.setOpenModal}
      title=""
      noCloseIcon={false}
    >
      <>
        <div className="bg-white pb-5">
          <div className=" heading-part px-7 pb-4 border-b">
            <h4 className="small-title font-bold text-[#c00000]">
              Add Page Name
            </h4>
          </div>
          <div className="filter-info grid grid-cols-12 gap-6 p-8">
            <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
              <label
                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                for="default"
              >
                Main Module <span className="text-[#c00000]">*</span>
              </label>
              <DropdownSelect
                drpIdentity={"AddMainMenu"}
                optionArray={props.mainModules}
                setFilterParameters={props.setFilterParameters}
                value={props.subModulePageDetails.pagesMainModuleId}
              />
              <ValidationText
                error={props.validState.error.pagesMainModuleId}
              />
            </div>
            <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
              <label
                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                for="default"
              >
                Sub Module <span className="text-[#c00000]">*</span>
              </label>
              <DropdownSelect
                drpIdentity={"AddSubMenu"}
                optionArray={props.subModules ? props.subModules : []}
                setFilterParameters={props.setFilterParameters}
                value={props.subModulePageDetails.pagesSubModuleId}
              />
              <ValidationText error={props.validState.error.pagesSubModuleId} />
            </div>
            <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
              <label
                className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                for="default"
              >
                Sub Module Page Name <span className="text-[#c00000]">*</span>
              </label>

              <input
                className="w-full"
                type="text"
                id="pageName"
                name="pageName"
                onChange={(event) => props.handleChange(event, "PageName")}
                onBlur={() => props.validateField("pageName")}
                value={props.subModulePageDetails.pageName}
              />
              <ValidationText error={props.validState.error.pageName} />
            </div>
            <div className="xl:col-span-12 lg:col-span-12 col-span-12">
              <div className="flex items-end justify-end h-full">
                {/* <Link to="/AddEditStrategicMember"> */}
                <button
                  className="btn btn-red text-white font-semibold link mt-7"
                  onClick={() => props.addPageNameForSubModule()}
                >
                  <span className="text-xl text-white font-semibold tracking-normal">
                    Add Page Name
                  </span>
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </>
    </ModalBasic>
  );
};
export default AddNewPageNameForSubModule;
