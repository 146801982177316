import React from 'react'
import { Link } from 'react-router-dom'

export default function CreateButton(props) {
  return (
    <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-3">
      {/* <Link to='/AddEditLeadershipPosition' > */}
      <Link to={props.redirect}>
        <button className="btn btn-red text-white" >
          <span className="text-xl text-white font-semibold tracking-normal"> {props.text} </span>
        </button></Link>
    </div>
  )
}
