import { IsProdMode } from "../../utils/AppSetting";
// Web API url
//export const APIURL = (IsProdMode) ? "http://testingimsapi.echemportal.net/api/" : "https://localhost:44321/api/";

// export const APIURL = (IsProdMode) ? process.env.REACT_APP_PreProdApiURL : process.env.REACT_APP_DevApiURL;
export const APIURL = "https://api.csatesting.infixsofttech.com/api/";
// export const APIURL = "https://api.sgtech-test.infixsofttech.com/api/";
// export const APIURL = "https://sgtech-api-test.flyingcapetech.com/api/";
// New Test API URL
// export const APIURL = "https://api.scal-test.infixsofttech.com/api/";


//Authentication services
export const AdminLogin = "Account/AdminLogin";

//Admin dashboard services
export const GetInboxListForDashboard = "AdminDashboard/GetInboxListForDashboard"

//Dashboard services
export const GetDashboardDetailsById = "CorporateMember/GetDashboardDetailsById?id={0}";
export const GetAdminDashboardDetail = "Dashboard/GetAdminDashboardDetail";//s1
export const UpdateCyberConnectAccountDetails = "CorporateMember/UpdateCyberConnectAccountDetails"
export const GetCyberConnectContactRequestDetailsById = "CorporateMember/GetCyberConnectContactRequestDetailsById?id={0}"
export const GenerateMembershipReport = "CorporateMember/GenerateMembershipReport"
export const GetMemberReportList = "CorporateMember/GetMemberReportList"
export const DownloadReport = "CorporateMember/DownloadReport"
export const GetDashboardCounts = "CorporateMember/GetDashboardCounts"

export const UpdateDashboardOrgBasicInfo = "CorporateMember/UpdateDashboardOrgBasicDetails"
export const UpdateDashboardOrgProfileInfo = "CorporateMember/UpdateDashboardOrgProfileDetails"

// CyberConnectMemberServices services
export const GetAllCyberMembersList = "CorporateMember/GetAllCyberMembersList"
export const GetAllCyberMembersContactList = "CorporateMember/GetAllCyberMembersContactList"
export const GetClickAnalyticsList = "CorporateMember/GetClickAnalyticsList"

//Common Services
export const GetAllDropdownForCorporateMember = "CorporateMember/GetAllDropdownForCorporateMember"
export const uploadPDF = "Common/UploadPDF";
export const StoreBase64Image = "Common/StoreBase64Image";
export const GetImage = "Common/GetImage";
export const GetPDF = "Common/GetPDF?type={0}&&fileName={1}"

//Pages Management Services
export const GetPagesMainModulesList = "PagesManagement/GetPagesMainModulesList";
export const GetPagesDetailsList = "PagesManagement/GetPagesDetailsList";
export const GetAllDropdownsForViewPages = "PagesManagement/GetAllDropdownsForViewPages";
export const GetPageDetailByIds = "PagesManagement/GetPageDetailByIds?MainMenuId={0}&SubMenuId={1}&PageNameId={2}";
export const GetSubModulesListByMainModuleId = "PagesManagement/GetPagesSubModulesListById?id={0}";
export const SavePageDetails = "PagesManagement/AddEditPageDetail";
export const UpdatePageDetails = "PagesManagement/UpdatePageDetails";
export const GetAllDropdownsForPages = "PagesManagement/GetAllDropdownsForPages";
export const AddEditPartialPage = "PagesManagement/AddEditPartialPage";
export const GetPartialPageComponentsById = "PagesManagement/GetPartialPageComponentsById?id={0}";
export const GetSubPagesListForSubModuleById = "PagesManagement/GetSubPagesListForSubModuleById?id={0}";
export const AddPageNameForSubModule = "PagesManagement/AddPageNameForSubModule";
export const DeletePageNamesById = "PagesManagement/DeletePageNamesById";
export const GetPageDetailByPagesDetailsId = "PagesManagement/GetPageDetailByPagesDetailsId?id={0}";
export const DeleteStandAlonePageById = "PagesManagement/DeleteStandAlonePageById?id={0}";

// Field Management
export const GetAllTagTypeDropdown = "FieldManagement/GetAllTagTypeDropdown";
export const GetAllTagList = "FieldManagement/GetAllTagList";
export const DeleteTags = "FieldManagement/DeleteTags";
export const GetTagDetailById = "FieldManagement/GetTagDetailById?id={0}";
export const AddEditTag = "FieldManagement/AddEditTag";
export const GetAllProjectTypeDropdown = "FieldManagement/GetAllProjectTypeDropdown";
export const GetEmailTemplateList = "FieldManagement/GetEmailTemplateList";
export const DeleteEmailTemplate = "FieldManagement/DeleteEmailTemplate";
export const GetEmailTemplateById = "FieldManagement/GetEmailTemplateById?id={0}";
export const AddEditEmailTemplate = "FieldManagement/AddEditEmailTemplate";
export const GetDropdownRecordList = "FieldManagement/GetDropdownRecordList";
export const GetDropdownDatabyId = "FieldManagement/GetDropdownDatabyId?id={0}";
export const DeleteDropdownData = "FieldManagement/Deletedropdowndata";
export const AddEditDropdownData = "FieldManagement/AddEditDropdownData";
export const GetTableDataById = "FieldManagement/GetTableDataById?DropdownId={0}&TableDataId={1}";

// Admin
export const AddEditAdminDetails = "Account/AddEditAdminDetails";
export const GetRolesForAdmin = "Account/GetRolesForAdmin";
export const GetAdminDetailList = "Account/GetAdminDetailList";
export const GetAdminDetailById = "Account/GetAdminDetailById?id={0}";
export const DeleteAdminDetailsById = "Account/DeleteAdminDetailsById?id={0}";
export const ForgotPassword = "Account/ForgotPassword";
export const ResetPassword = "Account/ResetPassword";

export const SaveClarificationDetails = "CorporateMember/SaveClarificationDetails";
