import React from 'react'
import moment from 'moment';

function LatestEnquiry(props) {
    return (
        <div>
            <div className="py-4">
                <div className="relative justify-center latestenquiry p-4 pl-24 border-b border-[#fff]">
                    <div className="flex flex-row items-center justify-between title">
                        <div className="icon">Latest Enquiry</div>
                    </div>
                </div>
            <div className="overflow-x-auto">
                <div className="pb-4">
                    <div className="relative justify-center latestenquiry">
                        <table class="w-full">
                             
                           <tbody>
                                {props.inboxList ?
                                    props.inboxList.map((x, index) =>
                                    (
                                        <>
                                            <tr class="flex items-center py-5 pl-20 border-b border-[#fff]" >
                                                <td className='px-3 py-2 lg:w-[30%] w-[100px] font-bold'>
                                                {x.name}
                                                </td>
                                                <td className='px-3 py-2 lg:w-[40%] w-[100px] underline'>
                                                {x.leaveMessage}
                                                </td>
                                                <td className='px-3 py-2 lg:w-[30%] w-[100px] '>
                                                {moment(x.createdAt).format("DD MMMM YYYY")}
                                                </td>
                                            </tr>
                                        </>)
                                    )
                                    : null}
                            </tbody> 
                            
                        </table>
                    </div>
                </div>
            </div>
            </div> 
        </div>
    )
}

export default LatestEnquiry