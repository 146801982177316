import React from "react";

function CorporateMember(props) {
  return (
    <div>
      <div className="grid gap-8 py-4 grid-cols h-[300px]">
        <div className="relative justify-center corporateMember p-4 pl-24">
          <div className="flex flex-row items-center justify-between title">
            <div className="icon">Corporate Member</div>
            <div className="number mr-10">{props.dashboardCounts.totalCounts}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Approved</div>
            <div className="underline mr-10">{props.dashboardCounts.approvedCounts}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Submitted</div>
            <div className="underline mr-10">{props.dashboardCounts.submittedCounts}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Pending </div>
            <div className="underline mr-10">{props.dashboardCounts.pendingCounts}</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>Rejected</div>
            <div className="underline mr-10">{props.dashboardCounts.rejectedCounts}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CorporateMember;
