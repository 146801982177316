import React, { Component } from 'react'
import OrganisationBasicInformation from '../accountManagement/OrganisationBasicInformation'
import OrganisationProfile from '../accountManagement/OrganisationProfile'
import SwalServices from '../../services/swalServices/SwalServices'
import { CommonValidationMessages } from '../../utils/Messages';
import BorderSection from '../../components/BorderSection/BorderSection'
import { isValidForm, validate } from '../../utils/validation/CommonValidator'
import SendClarification from '../../components/sendClarification/SendClarification';
import CommonServices from '../../services/axiosServices/apiServices/CommonServices'
import DashboardServices from '../../services/axiosServices/apiServices/DashboardServices'
import { decryptionData } from '../../utils/Encryption';
import { Navigate } from 'react-router-dom';
import CyberConnectMemberServices from '../../services/axiosServices/apiServices/CyberConnectMemberServices';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';
import { saveAs } from 'file-saver';
const regex = /(<([^>]+)>)/gi;

export default class ViewCyberConnectAccount extends Component {
  constructor(props) {
    super(props);
    this.commonServices = new CommonServices();
    this.cyberConnectMemberServices = new CyberConnectMemberServices();
    this.dashboardDetails = new DashboardServices();
    this.swalServices = new SwalServices();
    this.state = {
      isLoading: true,
      isLoadingBtn: "",
      cyberConnectMemberId: 0,
      redirect: "",
      solutionCategoryModel: false,
      sendClarificationModal: false,
      industryServed: [],
      SSICCodedata: [],
      GlobalCertification: [],
      GeographicalMarketsServed: [],
      SolutionCategorisationdata: [],
      countCompanyDescription: 0,
      countProductDescription: 0,
      orgBasicInfo: {
        cyberConnectMemberId: 0,
        clarification: '',
        businessRegistrationNumber: "",
        companyName: "",
        selectIndustryServed: [],
        representativeFirstName: "",
        representativeLastName: "",
        designation: "",
        businessEmail: "",
        businessContactNumber: "",
        selectSSICCode: [],
        companyLogo: "",
        selectGeographicalMarketsServed: [],
        selectSolutionCategorisation: [],
        selectGlobalCertification: [],
        companyWebsite: "",
        LinkedIn: "",
        IndustryOthers: "",
        others: "",
        companyDescription: "",
        productDescription: "",
        companyInformation: "",
        industryServedTypeId: 0,
        SSICCodeTypeId: 0,
        GlobalCertificationTypeId: 0,
        GeographicalMarketTypeId: 0,
        SolutionCategorisationTypeId: 0,
        isValidCyberMissionAndCyberPlaybook: true,
        accountStatus: ''
        // industryServedForNR: '',
        // ssicCodeForNR: ''
      },
      isOthers: false,
      isIndustryOthers: false,
      validationRuleOrgBasicInfo: {
        businessRegistrationNumber: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Business Registration Number"),
          },
          {
            type: "companyUENRegex",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Valid Business Registration Number"),
          },
        ],
        companyName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "company name"),
          },
        ],
        selectIndustryServed: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Industry Served"),
          },
        ],
        representativeFirstName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Representative First Name"),
          },
        ],
        representativeLastName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Representative Last Name"),
          },
        ],
        designation: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Designation"),
          },
        ],
        businessEmail: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Business Email"),
          },
          {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Valid Email address"),
          },
        ],
        businessContactNumber: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Business Contact Number"),
          },
          {
            type: "number",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Valid Business Contact Number"),
          },
        ],
        selectSSICCode: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "SSIC Code"),
          },
        ]
      },
      validationRuleProfileBasicInfo: {
        companyLogo: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Company Logo"),
          },
        ],
        companyDescription: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Company Description"),
          },
          {
            type: "maxLength",
            maxLength: 1000,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less then 1000 character"),
          },
        ],
        productDescription: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Product Description"),
          },
          {
            type: "maxLength",
            maxLength: 1000,
            message: CommonValidationMessages.FieldRequired.replace("{0}", "less then 1000 character"),
          },
        ],
        companyWebsite: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "Company Website"),
          },
          {
            type: "isValidateURL",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "proper website url"),
          },
        ],
        selectGeographicalMarketsServed: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Geographical Markets Served"),
          },
        ],
        selectSolutionCategorisation: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Solution Categorisation"),
          },
        ],
        selectGlobalCertification: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "Global Certification"),
          },
        ]
      },
      validState: {
        isValid: true,
        error: {},
      },
    }
  }

  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = { ...this.state.orgBasicInfo };
    if (name === "businessContactNumber") {
      let isnum = Number(value);
      if ((isnum >= 0 || value === '+') && isnum !== '') {
        detail[name] = value;
      }
    } else {
      detail[name] = value;
    }
    this.setState({ orgBasicInfo: detail });
  }

  validateOrgBasicField = (key) => {
    const newValidState = validate(
      key,
      this.state.orgBasicInfo,
      this.state.validationRuleOrgBasicInfo,
      this.state.validState
    );

    this.setState({ validState: newValidState });
  };

  validateOrgProfileField = (key) => {
    const newValidState = validate(
      key,
      this.state.orgBasicInfo,
      this.state.validationRuleProfileBasicInfo,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.orgBasicInfo,
      this.state.validationRuleOrgBasicInfo,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  setVideoDescription = (data, type) => {
    if (type === "companyDescription") {
      if (typeof data === 'string') {
        let detail = this.state.orgBasicInfo;
        detail["companyDescription"] = data;
        this.setState({
          orgBasicInfo: { ...detail },
        }, () => {
          const result = this.state.orgBasicInfo.companyDescription.replace(regex, "");
          let str = result.replaceAll("<p>", "")
            .replaceAll("</p>", "")
            .replaceAll("\n", "")
            .replaceAll("&nbsp;", " ").replaceAll("</br>", " ");
          this.setState({ countCompanyDescription: str.length })
        });
      }
    }
    if (type === "productDescription") {
      if (typeof data === 'string') {
        let detail = this.state.orgBasicInfo;
        detail["productDescription"] = data;
        this.setState({
          orgBasicInfo: { ...detail },
        }, () => {
          const result = this.state.orgBasicInfo.productDescription.replace(regex, "");
          let str = result.replaceAll("<p>", "")
            .replaceAll("</p>", "")
            .replaceAll("\n", "")
            .replaceAll("&nbsp;", " ").replaceAll("</br>", " ");
          this.setState({ countProductDescription: str.length })
        });
      }
    }
  };

  saveMainImage = (file, keyId, imageIdentity) => {
    file.storagePath = imageIdentity;
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("Photo Uploaded");
        if (file.storagePath === "CorporateMembers") {
          let detail = this.state.orgBasicInfo;
          detail["companyLogo"] = response.fileName;
          this.setState({ orgBasicInfo: { ...detail } });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  componentDidMount() {
    this.getAllDropdownForCorporateMember();
  }

  handleChangeMultiDropdown(selected, identity) {
    var details = { ...this.state.orgBasicInfo }
    if (identity === "industryServed") {
      details.selectIndustryServed = selected;
      const other = selected.find((drp) => drp.label === "Others");
      if (other) {
        let others = other["label"] === 'Others' ? true : false;
        this.setState({ isIndustryOthers: others })
      } else {
        this.setState({ isIndustryOthers: false })
        details.IndustryOthers = ""
      }
      this.setState({ orgBasicInfo: details }, () => {
        this.validateOrgBasicField("selectIndustryServed");
      });
    }
    if (identity === "SSICCodedata") {
      details.selectSSICCode = selected;
      this.setState({ orgBasicInfo: details }, () => {
        this.validateOrgBasicField("selectSSICCode");
      });
    }
    if (identity === "GeographicalMarketsServed") {
      details.selectGeographicalMarketsServed = selected;
      this.setState({ orgBasicInfo: details }, () => {
        this.validateOrgProfileField("selectGeographicalMarketsServed");
      });
    }
    if (identity === "SolutionCategorisation") {
      details.selectSolutionCategorisation = selected;
      this.setState({ orgBasicInfo: details }, () => {
        this.validateOrgProfileField("selectSolutionCategorisation");
      });
    }
    if (identity === "GlobalCertification") {
      details.selectGlobalCertification = selected;
      const other = selected.find((drp) => drp.label === "Others");
      if (other) {
        let others = other["label"] === 'Others' ? true : false;
        this.setState({ isOthers: others })
      } else {
        this.setState({ isOthers: false })
        details.others = ""
      }
      this.setState({ orgBasicInfo: details }, () => {
        this.validateOrgProfileField("selectGlobalCertification");
      });
    }
    this.setState({ orgBasicInfo: details });
  }

  getAllDropdownForCorporateMember = () => {
    this.commonServices.getAllDropdownForCorporateMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        var industryType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Industry");
        industryType.map(function (item) { delete item["dropdownName"]; });
        industryType = industryType.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ industryServed: industryType });

        var SSICCodeType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "SSICCode");
        SSICCodeType.map(function (item) { delete item["dropdownName"]; });
        SSICCodeType = SSICCodeType.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ SSICCodedata: SSICCodeType });

        var GeographicalMarkets = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "GeographicalMarket");
        GeographicalMarkets.map(function (item) { delete item["dropdownName"]; });
        GeographicalMarkets = GeographicalMarkets.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ GeographicalMarketsServed: GeographicalMarkets });

        var SolutionCategorisation = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "SolutionCategorisation");
        SolutionCategorisation.map(function (item) { delete item["dropdownName"]; });
        SolutionCategorisation = SolutionCategorisation.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ SolutionCategorisationdata: SolutionCategorisation });

        var CyberSecurityIndustryAndGlobalCertification = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "GlobalCertification");
        CyberSecurityIndustryAndGlobalCertification.map(function (item) { delete item["dropdownName"]; });
        CyberSecurityIndustryAndGlobalCertification = CyberSecurityIndustryAndGlobalCertification.map((p) => ({ value: p.id, label: p.name }));
        this.setState({ GlobalCertification: CyberSecurityIndustryAndGlobalCertification });

        let param = window.location.href;
        if (param.includes("CyberConnectMemberId")) {
          var name = param.split("=")
          let id = name[1]
          let CyberConnectMemberId = Number(decryptionData(id));
          let Info = this.state.orgBasicInfo
          Info.cyberConnectMemberId = CyberConnectMemberId
          this.setState({ cyberConnectMemberId: CyberConnectMemberId, orgBasicInfo: Info }, () => {
            this.getDashboardDetailsById();
          });
        }
      }
    });
  }

  getDashboardDetailsById = () => {
    this.setState({ isLoading: true });
    let id = this.state.cyberConnectMemberId;
    this.dashboardDetails.getDashboardDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        let res = this.state.orgBasicInfo
        res.businessRegistrationNumber = response.responseItem.responseContent.companyUENNumber
        res.representativeFirstName = response.responseItem.responseContent.firstName
        res.representativeLastName = response.responseItem.responseContent.lastName
        res.LinkedIn = response.responseItem.responseContent.linkedIn
        res.companyName = response.responseItem.responseContent.companyName
        res.designation = response.responseItem.responseContent.designation
        res.businessEmail = response.responseItem.responseContent.businessEmail
        res.businessContactNumber = response.responseItem.responseContent.businessContactNumber
        res.IndustryOthers = response.responseItem.responseContent.industryOthers
        res.others = response.responseItem.responseContent.others

        res.companyDescription = response.responseItem.responseContent.companyDescription
        let companyDes = response.responseItem.responseContent.companyDescription.replace(regex, "").replaceAll("<p>", "")
          .replaceAll("</p>", "")
          .replaceAll("\n", "")
          .replaceAll("&nbsp;", " ").replaceAll("</br>", " ");
        this.setState({ countCompanyDescription: companyDes.length })

        res.productDescription = response.responseItem.responseContent.productDescription
        let productDes = response.responseItem.responseContent.companyDescription.replace(regex, "").replaceAll("<p>", "")
          .replaceAll("</p>", "")
          .replaceAll("\n", "")
          .replaceAll("&nbsp;", " ").replaceAll("</br>", " ");
        this.setState({ countProductDescription: productDes.length })

        res.companyWebsite = response.responseItem.responseContent.companyWebsite
        res.companyLogo = response.responseItem.responseContent.companyLogo
        res.companyInformation = response.responseItem.responseContent.companyInformationUseCasesAndBrochures
        res.accountStatus = response.responseItem.responseContent.accountStatus

        if (response.responseItem.responseContent.cyberSecurityIndustryGlobalCertification && response.responseItem.responseContent.cyberSecurityIndustryGlobalCertification !== 0 && response.responseItem.responseContent.cyberSecurityIndustryGlobalCertification !== "0") {
          var array = (response.responseItem.responseContent.cyberSecurityIndustryGlobalCertification !== null || response.responseItem.responseContent.cyberSecurityIndustryGlobalCertification !== "") ? response.responseItem.responseContent.cyberSecurityIndustryGlobalCertification.split(',') : [];
          res.selectGlobalCertification = [];
          const filteredArray = array.filter(value => value !== "0");
          if(filteredArray && filteredArray.length > 0){
            filteredArray.forEach(topic => {
              res.selectGlobalCertification.push(this.state.GlobalCertification.find(topics => topics.value === Number(topic)));
            });
          }
          let data = res.selectGlobalCertification
          if (data) {
            const other = data.find((drp) => drp.label === "Others");
            if (other) {
              let others = other.label === 'Others' ? true : false;
              this.setState({ isOthers: others })
            }
          }
        }

        if (response.responseItem.responseContent.industryServedId && response.responseItem.responseContent.industryServedId !== 0 && response.responseItem.responseContent.industryServedId !== "0") {
          var array = (response.responseItem.responseContent.industryServedId !== null || response.responseItem.responseContent.industryServedId !== "") ? response.responseItem.responseContent.industryServedId.split(',') : [];
          res.selectIndustryServed = [];
          const filteredArray = array.filter(value => value !== "0");
          if(filteredArray && filteredArray.length > 0){
          filteredArray.forEach(topic => {
            res.selectIndustryServed.push(this.state.industryServed.find(topics => topics.value === Number(topic)));
          });
        }
          let data = res.selectIndustryServed
          if (data) {
            const other = data.find((drp) => drp.label === "Others");
            if (other) {
              let others = other.label === 'Others' ? true : false;
              this.setState({ isIndustryOthers: others })
            }
          }
        }


        if (response.responseItem.responseContent.ssicCodeId && response.responseItem.responseContent.ssicCodeId !== 0 && response.responseItem.responseContent.ssicCodeId !== "0") {
          var array = (response.responseItem.responseContent.ssicCodeId !== null || response.responseItem.responseContent.ssicCodeId !== "") ? response.responseItem.responseContent.ssicCodeId.split(',') : [];
          res.selectSSICCode = [];
          const filteredArray = array.filter(value => value !== "0");
          if(filteredArray && filteredArray.length > 0){
            filteredArray.forEach(topic => {
              res.selectSSICCode.push(this.state.SSICCodedata.find(topics => topics.value === Number(topic)));
            });
          }
        }
        if (response.responseItem.responseContent.geographicalMarketsServedId && response.responseItem.responseContent.geographicalMarketsServedId !== 0 && response.responseItem.responseContent.geographicalMarketsServedId !== "0") {
          var array = (response.responseItem.responseContent.geographicalMarketsServedId !== null || response.responseItem.responseContent.geographicalMarketsServedId !== "") ? response.responseItem.responseContent.geographicalMarketsServedId.split(',') : [];
          res.selectGeographicalMarketsServed = [];
          const filteredArray = array.filter(value => value !== "0");
          if(filteredArray && filteredArray.length > 0){
          filteredArray.forEach(topic => {
            res.selectGeographicalMarketsServed.push(this.state.GeographicalMarketsServed.find(topics => topics.value === Number(topic)));
          });
        }
        }
        if (response.responseItem.responseContent.solutionCategorisationId && response.responseItem.responseContent.solutionCategorisationId !== 0 && response.responseItem.responseContent.solutionCategorisationId !== "0") {
          var array = (response.responseItem.responseContent.solutionCategorisationId !== null || response.responseItem.responseContent.solutionCategorisationId !== "") ? response.responseItem.responseContent.solutionCategorisationId.split(',') : [];
          res.selectSolutionCategorisation = [];
          const filteredArray = array.filter(value => value !== "0");
          if(filteredArray && filteredArray.length > 0){
          filteredArray.forEach(topic => {
            res.selectSolutionCategorisation.push(this.state.SolutionCategorisationdata.find(topics => topics.value === Number(topic)));
          });
        }
        }
        this.setState({ orgBasicInfo: res });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  sendClarification = (event) => {
    event.stopPropagation();
    this.setState({ sendClarificationModal: true });
  }

  saveClarificationDetails = () => {
    this.setState({ isLoadingBtn: "Clarification" })
    let request = {
      cyberConnectMemberId: this.state.cyberConnectMemberId,
      clarificationDetails: this.state.orgBasicInfo.clarification,
    };
    this.cyberConnectMemberServices.saveClarificationDetails(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.swalServices.Success("Success");
        this.setState({
          slotsPricingTable: response.responseItem.responseContent,
        });
        this.swalServices.Success("Clarification mail sent successfully");
        this.setState({ setSendClarifications: false });
        this.setState({ redirect: "/CyberConnectAccountApproval" });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingBtn: "" })
    });
  };

  UpdateCyberConnectAccountDetails = (accountStatus) => {
    if (accountStatus === 'Approved') {
      this.setState({ isLoadingBtn: "Approved" })
    }
    if (accountStatus === 'Rejected') {
      this.setState({ isLoadingBtn: "Rejected" })
    }
    let request = {
      CyberConnectMemberId: this.state.cyberConnectMemberId,
      accountStatus: accountStatus
    }
    this.dashboardDetails.UpdateCyberConnectAccountDetails(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        if (accountStatus === 'Approved') {
          this.swalServices.Success("Member successfully approved");
        }
        else if (accountStatus === 'Rejected') {
          this.swalServices.Success("Member successfully Rejected");
        }
        this.setState({ redirect: "/CyberConnectAccountApproval" });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingBtn: "" })
    });
  }

  downloadPdf = () => {
    this.setState({ isLoadingPdf: true });
    let request = [
      "Cases_Brochures_File",
      this.state.orgBasicInfo.companyInformation
    ]
    this.commonServices.getPDF(request).then((response) => {
      if (response.data != null && response.status === 200) {
        var filename = this.state.orgBasicInfo.companyInformation;
        var file = new Blob([response.data], { type: "application/pdf" });
        URL.createObjectURL(file);
        saveAs(file, filename + ".pdf");
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoadingPdf: false });
    });
  }

  isValidateProfile = () => {
    const newValidState = isValidForm(
      this.state.orgBasicInfo,
      this.state.validationRuleProfileBasicInfo,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  updateDashboardOrgBasicInfo = () => {
    let isValidateAllFields = this.isValidateAllFields();
    if (isValidateAllFields) {
      this.setState({ isBtnLoading: "basicInfo" });
      let request = this.state.orgBasicInfo;
      // let details = getAuthProps();
      // request.updatedBy = details.adminUserId ? details.adminUserId : 0;
      // request.isUpdatedFromWeb = false
      // if (request.selectIndustryServed.length > 0) {
      //   var arr1 = request.selectIndustryServed.map(function (item) {
      //     delete item.bad; return item.value;
      //   });
      //   request.industryServedForNR = arr1.join([","]);
      // } else {
      //   request.industryServedForNR = "";
      // }
      // if (request.selectSSICCode.length > 0) {
      //   var arr2 = request.selectSSICCode.map(function (item) {
      //     delete item.bad; return item.value;
      //   });
      //   request.ssicCodeForNR = arr2.join([","]);
      // } else {
      //   request.ssicCodeForNR = "";
      // }
      this.dashboardDetails.updateDashboardOrgBasicInfo(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorColumn === "Email") {
            this.swalServices.Warning("Email address already exists");
          } else if (response.responseItem.responseContent.errorColumn === "CompanyName") {
            this.swalServices.Warning("Company name already exists");
          } else if (response.responseItem.responseContent.errorColumn === "CompanyUEN") {
            this.swalServices.Warning("CompanyUEN name already exists");
          }
          else {
            this.swalServices.SuccessWithAction("Organisation Basic Information Updated", "OK")
              .then(() => { this.getDashboardDetailsById() });
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isBtnLoading: "" });
      });
    }
  }

  updateDashboardProfileInfo = () => {
    let isValidateProfile = this.isValidateProfile();
    if (isValidateProfile) {
      this.setState({ isBtnLoading: "ProfileInfo" });
      let request = this.state.orgBasicInfo;
      // let details = getAuthProps();

      // request.updatedBy = details.adminUserId ? details.adminUserId : 0;

      // request.isUpdatedFromWeb = false
      this.dashboardDetails.updateDashboardOrgProfileInfo(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.SuccessWithAction("Organisation Profile Information Updated", "OK")
            .then((res) => { this.getDashboardDetailsById() });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isBtnLoading: "" });
      });
    }
  }

  onFileChange = (event, identity) => {
    const file = event.target.files[0];
    if (identity === "Upload_PDF") {
      if (file && file.type === "application/pdf") {
        let uploadFile = { ...this.state.resFile };
        uploadFile.filename = file.name;
        uploadFile["file"] = file;
        uploadFile.storagePath = "Cases_Brochures_File";
        // uploadFile.storagePath = "Resource";
        let detail = { ...this.state.orgBasicInfo };
        detail.companyInformation = file.name;
        this.setState({ orgBasicInfo: detail },
          // () => this.validateField("companyInformation")
        );
        this.commonServices.uploadPDF(uploadFile).then((response) => {
          if (response != null) {
            let detail = { ...this.state.orgBasicInfo };
            detail.companyInformation = response.fileName;
            this.setState({ orgBasicInfo: detail });
            this.swalServices.Success("File Uploaded");
          }
          else {
            this.swalServices.Error(response.message);
          }
        })
      }
      else {
        this.swalServices.Error("Please Upload only PDF file !");
      }
    }
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <>
        <div className='bg-[#e1e7ee] p-10'>
          <div className='white-card h-100'>
            <div className='text-2xl text-[#757575] font-bold p-5 ml-2'>
              Account Details
            </div>
            <hr className='border-[#c9c9c9]'></hr>
            <div className='p-10'>
              <BorderSection
                img="/user-icon-information.svg"
                col1Title="Organisation Basic Information"
                col2Title={this.state.orgBasicInfo.accountStatus ? this.state.orgBasicInfo.accountStatus : ''}>
                <OrganisationBasicInformation
                  industryServed={this.state.industryServed}
                  SSICCodedata={this.state.SSICCodedata}
                  value={this.state.orgBasicInfo}
                  validationMessages={this.state.validState}
                  onblur={(key) => this.validateOrgBasicField(key)}
                  onchange={(e) => this.handleChange(e)}
                  onDropdownChange={this.handleChangeMultiDropdown.bind(this)}
                  updateDashboardOrgBasicInfo={this.updateDashboardOrgBasicInfo.bind(this)}
                  isLoading={this.state.isLoading}
                  isBtnLoading={this.state.isBtnLoading}
                  isIndustryOthers={this.state.isIndustryOthers}
                />
              </BorderSection>
              <BorderSection
                img="/user-icon-information.svg"
                col1Title="Organisation Profile"
                onClick={() => null}
                col2Title={this.state.orgBasicInfo.accountStatus ? this.state.orgBasicInfo.accountStatus : ''}>
                <OrganisationProfile
                  GlobalCertification={this.state.GlobalCertification}
                  GeographicalMarketsServed={this.state.GeographicalMarketsServed}
                  SolutionCategorisationdata={this.state.SolutionCategorisationdata}
                  value={this.state.orgBasicInfo}
                  onblur={(key) => this.validateOrgProfileField(key)}
                  validationMessages={this.state.validState}
                  onchange={(e) => this.handleChange(e)}
                  onDropdownChange={this.handleChangeMultiDropdown.bind(this)}
                  onTinyEceEditorChange={(data, type) => this.setVideoDescription(data, type)}
                  onSaveImage={this.saveMainImage.bind(this)}
                  isLoading={this.state.isLoading}
                  countCompanyDescription={this.state.countCompanyDescription}
                  countProductDescription={this.state.countProductDescription}
                  downloadPdf={this.downloadPdf.bind(this)}
                  updateDashboardProfileInfo={this.updateDashboardProfileInfo.bind(this)}
                  isBtnLoading={this.state.isBtnLoading}
                  onFileChange={this.onFileChange.bind(this)}
                  isOthers={this.state.isOthers}
                />
              </BorderSection>
            </div>
            <div className='flex justify-end'>
              <div className="items-end h-full mb-8 mr-9">
                <button
                  className="btn btn-lighblue-border text-xl font-semibold"
                  onClick={(event) => this.sendClarification(event)}
                >
                  Send Clarification
                </button>
              </div>
              <div className="items-end h-full mb-8 mr-9">
                {/* {this.state.orgBasicInfo.accountStatus === 'Approved' ? '' : */}
                {this.state.isLoadingBtn === "Approved" ? <ButtonLoader loaderBtnClass="w-[135px]" loaderBtnColor="btn-lightblue" /> :
                  <button
                    className={`btn btn-lighblue text-white text-xl  font-semibold ${this.state.orgBasicInfo.accountStatus === 'Approved' ? 'disabled-btn' : ''}`}
                    onClick={() => this.UpdateCyberConnectAccountDetails("Approved")}
                  >
                    Approve
                  </button>

                }
              </div>
              <div className="items-end h-full mb-8 mr-9">
                {/* {this.state.orgBasicInfo.accountStatus === 'Rejected' ? '' : */}
                {this.state.isLoadingBtn === "Rejected" ? <ButtonLoader loaderBtnClass="w-[135px]" /> :
                  <button
                    className={`btn btn-red text-white text-xl  font-semibold ${this.state.orgBasicInfo.accountStatus === 'Rejected' ? 'disabled-btn' : ''}`}
                    onClick={() => this.UpdateCyberConnectAccountDetails("Rejected")}
                  >
                    Reject
                  </button>

                }
              </div>
            </div>
          </div>
        </div>
        <SendClarification
          sendClarificationModal={this.state.sendClarificationModal}
          orgBasicInfo={this.state.orgBasicInfo}
          saveClarificationDetails={this.saveClarificationDetails.bind(this)}
          setOpenModal={() => {
            this.setState({ sendClarificationModal: false })
          }
          }
          //handleChangeClarificationDetails={this.handleChangeClarificationDetails.bind(this)}          
          onClose={(e) => {
            e.stopPropagation();
            this.setState({ sendClarificationModal: false });
          }}
          onchange={(e) => this.handleChange(e)}
          isLoadingBtn={this.state.isLoadingBtn}
        />
      </>
    )
  }
}
