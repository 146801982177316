import React from 'react'
import Images from './Images';
import List from './List';
import ObjectComponent from './ObjectComponent';
import Richtext from './RichText';
import SimpleText from './SimpleText';

export default function Components(props) {
    return (
        <div className='col-span-12'>
            {(props && props.componentsArray.map((value, index) => {
                return (
                    <>
                        {
                            (value.componentTypeId === 1) ?
                                <Richtext
                                    key={`RichText_${index}`}
                                    componentDetails={value}
                                    identity={index}
                                    pageTypeId={props.pageData.pageTypeId}
                                    pageName={props.pageData.pageName}
                                    handleChange={(e, identity) => props.handleChangePartialPage(e, identity)}
                                    setEditorData={(data, identity, editor) => props.setEditorData(data, identity, 'RichText', editor)}
                                    removeComponent={() => props.removeComponent(index)}
                                />
                                :
                                null
                        }
                        {
                            (value.componentTypeId === 2) ?
                                <SimpleText
                                    key={`RichTextWithImage_${index}`}
                                    componentDetails={value}
                                    identity={index}
                                    pageTypeId={props.pageData.pageTypeId}
                                    handleChange={(e, identity) => props.handleChangePartialPage(e, identity)}
                                    setEditorData={(data, identity, editor) => props.setEditorData(data, identity, 'RichTextWithImage', editor)}
                                    saveMainImage={(file, id, imageIdentity, index) => props.saveMainImage(file, id, imageIdentity, index)}
                                    swapSection={() => props.swapSection(index)}
                                    removeComponent={() => props.removeComponent(index)}
                                    isSpecialFeaturePage={props.isSpecialFeaturePage && props.isSpecialFeaturePage === true ? true : false}
                                />
                                :
                                null
                        }
                        {
                            (value.componentTypeId === 3) ?
                                <List
                                    key={`ListComponent_${index}`}
                                    componentDetails={value}
                                    identity={index}
                                    iconArray={props.iconArray}
                                    listTypes={props.listTypes}
                                    pageTypeId={props.pageData.pageTypeId}
                                    handleChange={(e, identity) => props.handleChangePartialPage(e, identity)}
                                    handleChangeInList={(e, identity, listIndex) => props.handleChangeInList(e, identity, listIndex)}
                                    addNewListItem={(componentIndex) => props.addNewListItem(componentIndex)}
                                    saveMainImage={(file, id, imageIdentity, index) => props.saveMainImage(file, id, imageIdentity, index)}
                                    removeItemFromListComponent={(itemIndex, ComponentIndex) => props.removeItemFromListComponent(itemIndex, ComponentIndex)}
                                    removeComponent={() => props.removeComponent(index)}
                                />
                                :
                                null
                        }
                        {
                            (value.componentTypeId === 4) ?
                                <Images
                                    key={`ImagesComponent_${index}`}
                                    componentDetails={value}
                                    identity={index}
                                    pageTypeId={props.pageData.pageTypeId}
                                    handleChange={(e, identity) => props.handleChangePartialPage(e, identity)}
                                    handleChangeInImageComp={(e, identity, listIndex) => props.handleChangeInImageComp(e, identity, listIndex)}
                                    saveMainImage={(file, id, imageIdentity, index) => props.saveMainImage(file, id, imageIdentity, index)}
                                    addNewImage={(componentIndex) => props.addNewImage(componentIndex)}
                                    removeImageFromImagesComponent={(imageIndex, ComponentIndex) => props.removeImageFromImagesComponent(imageIndex, ComponentIndex)}
                                    removeComponent={() => props.removeComponent(index)}
                                />
                                :
                                null
                        }
                        {
                            (value.componentTypeId === 5) ?
                                <ObjectComponent
                                    key={`ObjectComponent_${index}`}
                                    componentDetails={value}
                                    identity={index}
                                    pageTypeId={props.pageData.pageTypeId}
                                    handleChange={(e, identity) => props.handleChangePartialPage(e, identity)}
                                    handleChangeInImageComp={(e, identity, listIndex) => props.handleChangeInImageComp(e, identity, listIndex)}
                                    saveMainImage={(file, id, imageIdentity, index) => props.saveMainImage(file, id, imageIdentity, index)}
                                    addNewImage={(componentIndex) => props.addNewImage(componentIndex)}
                                    addNewFieldInObject={() => props.addNewFieldInObject(index)}
                                    removeFieldInObject={(fieldIndex) => props.removeFieldInObject(fieldIndex, index)}
                                    handleChangeInObject={(name, value, fieldIndex) => props.handleChangeInObject(name, value, fieldIndex, index)}
                                    removeComponent={() => props.removeComponent(index)}
                                />
                                :
                                null
                        }
                    </>
                )
            }))}
        </div>
    )
}
