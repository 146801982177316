import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { signOut } from '../../utils/AuthenticationLibrary';
import Transition from '../../utils/Transition';


function Profile({
  align
}) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="text-slate-600 inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >

        <div className="flex items-center truncate">
          <span className=" header-txt truncate 2xl:mr-10 lg:mr-6 font-medium theme-text-color group-hover:hover-active-color">Admin</span>
        </div>

        <img className='menu-svg w-[30px] h-[40px] fill-[#6e6e6e] 2xl:mr-10 lg:mr-6 mr-4 mb-1' src='/user-icon.svg'></img>

      </button>

      <Transition
        className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)}>
          <ul>
            {/* <li>
              <Link className="font-medium text-sm theme-text-color hover:hover-active-color flex items-center py-1 px-3" to="/settings"
                onClick={() => setDropdownOpen(!dropdownOpen)} >
                Profile
              </Link>
            </li> */}
            <li>
              <Link className="font-medium text-sm theme-text-color hover:hover-active-color flex items-center py-1 px-3" to="/signin"
                onClick={() => signOut()}>
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default Profile;