export const msalConfig = {
    auth: {
      clientId: "0a4595d3-e609-4968-9639-f0711c9191e0",
      authority: "https://login.microsoftonline.com/9e380290-8b28-4fe8-a482-a254c651f7f0", 
      redirectUri: "http://localhost:3000/Login",
    },
    cache: {
      cacheLocation: "sessionStorage", 
      storeAuthStateInCookie: false, 
    }
  };
  
 
  export const loginRequest = {
    scopes: ["User.Read"]
  };
  
 
  export const graphConfig = {
      graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
  };