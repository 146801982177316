import React from "react";

const ButtonLoader = (props) => {
  return (
    <button className={`w-2/5  ${props.loaderBtnColor ? props.loaderBtnColor : "buttonload"} group relative text-xl border text-white focus:outline-none ${props.loaderBtnClass ? props.loaderBtnClass : ""}`}>
      <span className="absolute left-0 pl-7 flex items-center top-[50%] translate-y-[-50%]">
        <i className="fa fa-spinner fa-spin text-white text-[17px]"></i>
      </span>
      <span className="ml-8">
        Loading
      </span>
    </button>
  );
};

export default ButtonLoader;
