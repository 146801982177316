import { axiosGet, axiosGetAuthorize, axiosPost } from '../AxiosRequests';
import { GetAdminDashboardDetail, GetDashboardDetailsById, UpdateCyberConnectAccountDetails, GetInboxListForDashboard, GetDashboardCounts, UpdateDashboardOrgBasicInfo, UpdateDashboardOrgProfileInfo } from '../ApiEndPoints';

export default class DashboardServices {
    async getAdminDashboardDetail(request) {
        return axiosGetAuthorize(GetAdminDashboardDetail, request);
    }

    async getDashboardDetailsById(request) {
        return axiosGet(GetDashboardDetailsById, request);
    }

    async UpdateCyberConnectAccountDetails(request) {
        return axiosPost(UpdateCyberConnectAccountDetails, request);
    }

    async updateDashboardOrgBasicInfo(request) {
        return axiosPost(UpdateDashboardOrgBasicInfo, request);
    }

    async updateDashboardOrgProfileInfo(request) {
        return axiosPost(UpdateDashboardOrgProfileInfo, request);
    }

    //admin dashboard 
    async GetInboxListForDashboard(request) {
        return axiosGetAuthorize(GetInboxListForDashboard, request);
    }

    async getDashboardCounts(request) {
        return axiosGet(GetDashboardCounts, request);
    }
}