import React, { Component } from 'react'
import Table from '../../components/table/Table';
import moment from "moment";
import CyberConnectMemberServices from '../../services/axiosServices/apiServices/CyberConnectMemberServices';
import SwalServices from '../../services/swalServices/SwalServices';
import { isValidForm } from '../../utils/validation/CommonValidator';
import { ErrorMessage } from '../../utils/Messages';
import ValidationText from '../../utils/validation/ValidationText';
import { saveAs } from 'file-saver'

export default class CyberConnectReport extends Component {
    constructor(props) {
        super(props);
        this.cyberConnectMemberServices = new CyberConnectMemberServices();
        this.swalServices = new SwalServices();
        this.state = {
            companyDescriptionModal: false,
            solutionCategoryModel: false,
            connectNowModel: false,
            totalResultes: 0,
            totalCount: 0,
            cyberConnectReportList: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
            },
            dates: {
                submissionStartDate: "",
                submissionEndDate: ""
            },
            isLoading: false,
            validationRules: {
                submissionStartDate: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace("{0}", "Start Date"),
                    },
                ],
                submissionEndDate: [
                    {
                        type: "require",
                        message: ErrorMessage.FieldRequired.replace("{0}", "End Date"),
                    },
                    {
                        type: "minEndDate",
                        minEndDate: "submissionStartDate",
                        message: ErrorMessage.FieldRequired.replace("{0}", "greater end date")
                    }
                ],
            },
            validState: {
                isValid: true,
                error: {},
            },
        }
    }

    reset = () => {
        let reSetDate = this.state.dates
        reSetDate.submissionStartDate = ""
        reSetDate.submissionEndDate = ""
        this.setState({ dates: reSetDate })
    }

    actions = (element, index, value) => {
        return element !== "membershipReportId" ? null : (
            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt break-all text-center">
                <div className='text-[#7a98ad] underline underline-offset-auto cursor-pointer hover:text-[#c00000]'
                    onClick={(e) => {
                        e.preventDefault();
                        this.downloadReport(value)
                    }}
                >
                    Download
                </div>
            </td>
        );
    };

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.dates;
        detail[name] = value;
        this.setState({ dates: { ...detail } }, () => {
            // this.getAllCyberMembersList();
        });
    }

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.dates,
            this.state.validationRules,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    componentDidMount() {
        this.getMemberReportList();
    }

    getMemberReportList = () => {
        let pagination = { ...this.state.pagination }
        this.setState({ isLoading: true });
        this.cyberConnectMemberServices.getMemberReportList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
                details.map((key, index) => {
                    key.submissionStartDate = moment(key.submissionStartDate).format("DD MMM YYYY");
                    key.submissionEndDate = moment(key.submissionEndDate).format("DD MMM YYYY");
                    key.generationDate = moment(key.generationDate).format("DD MMM YYYY");
                    return null;
                });
                this.setState({ cyberConnectReportList: details });
                this.setState({
                    totalResultes: response.responseItem.responseContent.totalCount,
                    totalCount: response.responseItem.responseContent.totalCount
                });
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    downloadReport = (MembershipReportId) => {
        let req = { membershipReportId: MembershipReportId }
        this.cyberConnectMemberServices.downloadReport(req).then((res) => {
            if (res.data != null && res.status === 200) {
                var filename = "Cyber Connect Member Report"
                var blob = new Blob([res.data])
                saveAs(blob, filename + ".xlsx");
            } else {
                this.swalServices.Error(res.message);
            }
        })
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getMemberReportList();
    };

    generateMembershipReport = () => {
        let request = this.state.dates;
        if (this.isValidateAllFields()) {
            this.cyberConnectMemberServices.generateMembershipReport(request).then((response) => {
                if (response.responseItem.responseContent.errorColumn === 'SubmissionStartDate & SubmissionEndDate' && response.responseItem.responseContent.errorType === 'EXISTS') {
                    this.swalServices.Error(response.responseItem.responseContent.errorMessage);
                }
                else if (response.statusCode === 200 && response.responseItem != null) {
                    this.swalServices.Success("Member record generated successfully.");
                    this.reset()
                }
                else {
                    this.swalServices.Error(response.message);
                    this.reset()
                }
                this.getMemberReportList();
            });
        }
    }


    render() {
        return (
            <>
                <div className="main-body-section">
                    <div className="custom-card shadow-lg">
                        <div className="filter-info grid grid-cols-12 gap-6">
                            <div className="xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                                <label
                                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                    for="default"
                                >
                                    Submission Start Date
                                </label>
                                <input
                                    id="submissionStartDate"
                                    name="submissionStartDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    value={moment(
                                        this.state.dates.submissionStartDate
                                    ).format("yyyy-MM-DD")}
                                    onChange={(e) => this.handleChange(e)}
                                />
                                <ValidationText
                                    className="absolute left-0 top-[100%]"
                                    error={this.state.validState.error.submissionStartDate}
                                />
                            </div>
                            <div className="xl:col-span-4 lg:col-span-4 col-span-12 pr-12">
                                <label
                                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                    for="default"
                                >
                                    Submission End Date
                                </label>
                                <input
                                    id="submissionEndDate"
                                    name="submissionEndDate"
                                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                    type="date"
                                    placeholder='Select'
                                    value={moment(
                                        this.state.dates.submissionEndDate
                                    ).format("yyyy-MM-DD")}
                                    onChange={(e) => this.handleChange(e)}
                                    min={this.state.dates.submissionStartDate}
                                />
                                <ValidationText
                                    className="absolute left-0 top-[100%]"
                                    error={this.state.validState.error.submissionEndDate}
                                />
                            </div>
                            <div className="xl:col-span-4 lg:col-span-2 col-span-12">
                                <div className="flex items-end justify-end h-full pb-1">
                                    <button
                                        className="btn btn-red genrate-report-button text-white"
                                        onClick={() => this.generateMembershipReport()}
                                    >
                                        <span className="text-xl text-white font-semibold tracking-normal">
                                            Generate Report
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cyber-connect-account-table left-table mt-10">
                        <Table
                            columns={[
                                { name: "submissionStartDate", title: "Submission Start Date" },
                                { name: "submissionEndDate", title: "Submission End Date" },
                                { name: "generationDate", title: "Generated Date" },
                                { name: "numberOfRecords", title: "Number of Records" },
                                { name: "membershipReportId", title: "Action" },
                            ]}
                            rows={this.state.cyberConnectReportList}
                            isLoading={this.state.isLoading}
                            sortingColumns={[""]}
                            pagination={this.state.pagination}
                            isPaginationNotRequired={false}
                            totalResultes={this.state.totalResultes}
                            totalCount={this.state.totalCount}
                            setPagination={this.setPagination.bind(this)}
                            customScope={[
                                {
                                    column: "membershipReportId",
                                    renderTableData: this.actions.bind(this),
                                },
                            ]}
                        />
                    </div>
                </div>
            </>
        )
    }
}
