import React from 'react'
import ModalBasic from '../../pages/component/ModalBasic'

function CyberConenctContactRequestModal(props) {
  return (

    <ModalBasic
      id="Cyber-Conenct-Contact-Request-Modal"
      modalOpen={props.cyberConenctContactRequest}
      setModalOpen={props.setOpenModal}
      title="Details"
    >
      <div className='white-card h-100'>
        {/* <div className='text-2xl text-[#757575] font-bold px-5'>
          Details
        </div> */}
        <hr className='border-[#c9c9c9] mt-2'></hr>
        <div className='p-10'>
          <div className="grid grid-cols-12 lg:gap-6 gap-0 w-full ">
            <div className='xl:col-span-6 lg:col-span-6 col-span-12'>
              <div className="flield-sec">
                <h2 className="text-xl text-[#757575] font-bold mb-2">
                  Request Company Contact Name
                </h2>
                <input
                  className='outline-none appearance-none w-full custom-input'
                  type="text"
                  value={props.getCyberConnectContactRequestDetails.name}
                ></input>
              </div>
            </div>
            <div className='xl:col-span-6 lg:col-span-6 col-span-12'>
              <div className="flield-sec">
                <h2 className="text-xl text-[#757575] font-bold mb-2">
                  Request Company Contact Email
                </h2>
                <input
                  className='outline-none appearance-none w-full custom-input'
                  type="text"
                  value={props.getCyberConnectContactRequestDetails.emailAddress}
                ></input>
              </div>
            </div>
            <div className='xl:col-span-6 lg:col-span-6 col-span-12 '>
              <div className="flield-sec">
                <h2 className="text-xl text-[#757575] font-bold mb-2">
                  Target Company Contact Name
                </h2>
                <input
                  className='outline-none appearance-none w-full custom-input'
                  type="text"
                  value={props.getCyberConnectContactRequestDetails.companyName}
                ></input>
              </div>
            </div>
            <div className='xl:col-span-6 lg:col-span-6 col-span-12 '>
              <div className="flield-sec">
                <h2 className="text-xl text-[#757575] font-bold mb-2">
                  Target Company Contact Email
                </h2>
                <input
                  className='outline-none appearance-none w-full custom-input'
                  type="text"
                  value={props.getCyberConnectContactRequestDetails.businessEmail}
                ></input>
              </div>
            </div>
            <div className='xl:col-span-12 lg:col-span-12 col-span-12 mb-2'>
              <div className="flield-sec">
                <h2 className="text-xl text-[#757575] font-bold mb-2">
                  Message Details
                </h2>
            
                    <textarea
                        className="w-full h-full"
                        // placeholder="Comments"
                        id=""
                        rows="8"
                        type="text"
                        name={"companyDescription"}
                        value={props.getCyberConnectContactRequestDetails.leaveMessage}
                    // onChange={props.handleChange}
                    // onBlur={() => props.validateField("companyDescription")}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBasic>
  )
}

export default CyberConenctContactRequestModal