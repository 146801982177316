import { axiosGet, axiosGetMultiParamsWithOutEncryption, axiosPostWithoutEncryption } from '../AxiosRequests';
import { uploadPDF, StoreBase64Image, GetAllDropdownForCorporateMember, GetPDF } from '../ApiEndPoints';

export default class CommonServices {

    async getAllDropdownForCorporateMember(request) {
        return axiosGet(GetAllDropdownForCorporateMember, request);
    }

    async uploadPDF(request) {
        let formData = new FormData();
        formData.append("file", request.file);
        formData.append("filename", request.filename);
        formData.append("storagePath", request.storagePath);
        return axiosPostWithoutEncryption(uploadPDF, formData, true);
    }

    async storeBase64Image(request) {
        return axiosPostWithoutEncryption(StoreBase64Image, request, false);
    }

    async getPDF(request) {
        return axiosGetMultiParamsWithOutEncryption(GetPDF, request);
    }
}