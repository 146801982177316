import React, { Component } from "react";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import ActionEditButton from "../../components/UIComponent/ActionTableComponent/ActionEditButton";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { Navigate } from "react-router-dom";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { APIURL, GetImage } from "../../services/axiosServices/ApiEndPoints";
import { CommonSuccessMessages } from "../../utils/Messages";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import PageLoader from "../../components/common/pageLoader/PageLoader";
import { encryptAES } from "../../utils/Encryption";

export default class ViewEditAdminAccount extends Component {
  constructor(props) {
    super(props);
    this.adminServices = new AuthenticationServices();
    this.swalServices = new SwalServices();
    this.state = {
      steeringCommitteeData: [
        {
          id: 1,
          name: `xyz`,
          email: `admin+88@gmail.com`,
          profileImg: `/default-img.png`,
        },
        {
          id: 2,
          name: `AdminMaster`,
          email: `admin11@gmail.com`,
          profileImg: `/default-img.png`,
        },
        {
          id: 3,
          name: `AdminMaster1`,
          email: `admin10@gmail.com`,
          profileImg: `/default-img.png`,
        },
      ],
      route: "",
      isLoading: false,
      pagination: {
        pageNo: 1,
        pageSize: 50,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
      },
      totalResultes: 0,
      adminDetailsList: [],
      deletedBy: 0,
      deletedAppId: 0,
    };
  }

  componentDidMount() {
    let cookie = getAuthProps();
    const adminUserId = (cookie && cookie.adminUserId) ? Number(cookie.adminUserId) : 0;
    if (adminUserId > 0) {
      this.setState({ deletedBy: adminUserId, deletedAppId: 114 });
    }
    this.getAdminDetailList();
  }

  getAdminDetailList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.adminServices.getAdminDetailList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ adminDetailsList: response.responseItem.responseContent.itemList });
        this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  onDelete = (id) => {
    if (id !== 1) {
      this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Admin"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "admin details"),
        CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
          if (response) {
            let request = {};
            request.id = id;
            request.deletedBy = this.state.deletedBy;
            request.appId = this.state.deletedAppId;
            this.adminServices.deleteAdminDetailsById(request).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success("Admin details deleted successfully.");
                this.getAdminDetailList();
              }
              else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            })
          }
        })
    }
    else {
      this.swalServices.Error("This is main admin you can't delete it.");
    }
  }

  searchByName = (value) => {
    let detail = this.state.pagination;
    detail.pageNo = 1;
    detail.pageSize = 10;
    detail.searchText = value;
    this.setState({ pagination: { ...detail } });
    this.getAdminDetailList();
  }

  onEdit = (id) => {
    this.setState({ route: '/CreateAdminAccount/adminId?=' + encryptAES(id) });
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAdminDetailList();
  }

  render() {
    if (this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section ">
          <div className="grid grid-cols-6 gap-4 px-0 pb-3">
            {/* <h2 className="col-start-1 col-end-3 text-3xl font-bold text-[#757575]">
              {`Total:${this.state.totalResultes}`}
            </h2> */}
            <div className="col-end-7 col-span-2 grid grid-cols-3 items-center">
              <caption className="text-[#757575] text-xl">Search</caption>
              <input
                id="default"
                className="col-span-2 text-lg font-bold  h-[55px] w-full text-[#757575] border-white"
                type="text"
                onChange={(e) => this.searchByName(e.target.value)}
              />
            </div>
          </div>
          <main className="pb-10">
            <div className="w-full mx-auto">
              <div className="relative">
                <div className="view-accout-details-table">
                  <div className="mt-6 view-accout-details-table">
                    {this.state.isLoading ? <PageLoader /> :
                      <table className="w-full bg-white shadow-lg rounded-sm border border-slate-200 fixed_header">
                        {/* Table header */}
                        <thead className="table-bg text-xs font-semibold capitalize text-[#757575] border-t border-b border-slate-200 ">
                          <tr className="flex">
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                              <div className="font-bold tble-th-txt text-left">
                                Name
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                              <div className="font-bold tble-th-txt text-left">
                                Email
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[30%]">
                              <div className="font-bold tble-th-txt text-left">
                                Image
                              </div>
                            </th>
                            <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                              <div className="font-bold tble-th-txt text-left">
                                Action
                              </div>
                            </th>
                          </tr>
                        </thead>
                        {/* Table body */}
                        <tbody className="scrollit text-sm custom-tbody ">
                          {this.state.adminDetailsList.map((e, key) => {
                            return (
                              <>
                                <tr className="custom-tbl-td flex items-center">
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%]">
                                    <div className="text-left">{e.name + " " + e.lastName}</div>
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[30%]">
                                    <div className="text-left"> {e.email}</div>
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[30%]">
                                    <img
                                      className="w-32 border border-[#dfdfdf]"
                                      // src={e.profilePic}
                                      alt=""
                                      src={APIURL + GetImage + "?type=admin&&fileName=" + e.profilePic}
                                    ></img>
                                  </td>
                                  <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#757575] w-[20%] mr-5">
                                    <div className="text-left">
                                      <ActionContainer>
                                        <ActionEditButton
                                          value={e.adminID}
                                          id={key}
                                          index={key}
                                          onActionClick={this.onEdit.bind(this)}
                                          text="Edit"
                                        />
                                        <ActionDeleteButton
                                          value={e.adminID}
                                          id={key}
                                          index={key}
                                          onActionClick={this.onDelete.bind(this)}
                                          text="Delete"
                                        />
                                      </ActionContainer>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}

                        </tbody>
                      </table>
                    }
                  </div>
                  {/* <div className="mt-5">
                    <div className="text-sm text-[#757575] text-center sm:text-left mb-5">
                      <div className="no-of-page-title flex justify-end my-10 mx-5">
                        <p className="text-xl">
                          Showing{" "}
                          <span className="font-medium text-slate-600">1</span>{" "}
                          to{" "}
                          <span className="font-medium text-slate-600">3</span>{" "}
                          of{" "}
                          <span className="font-medium text-slate-600">10</span>{" "}
                          results
                        </p>
                      </div>
                    </div>
                    <nav
                      className="flex justify-between"
                      role="navigation"
                      aria-label="Navigation"
                    >
                      <div className="grow text-center">
                        <div>
                          <nav
                            className="flex items-center justify-around"
                            role="navigation"
                            aria-label="Navigation"
                          >
                            <div className="mr-2">
                              <a
                                href="#/"
                                className="btn bg-white border-slate-200 theme-color hover:text-[#C00000] py-4 pagination-btn"
                              >
                                <div className="flex items-center justify-between">
                                  <span className="mr-4">
                                    <svg
                                      width="17px"
                                      className="rotate-180"
                                      height="17px"
                                      viewBox="-5 0 23 23"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="icons"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <g
                                          id="ui-gambling-website-lined-icnos-casinoshunter"
                                          transform="translate(-484.000000, -159.000000)"
                                        >
                                          <g
                                            id="square-filled"
                                            transform="translate(50.000000, 120.000000)"
                                          >
                                            <path
                                              d="M435.76062,39.2902857 L446.638635,49.7417043 L446.699713,49.7959169 C446.885605,49.9745543 446.985701,50.2044182 447,50.4382227 L447,50.5617773 C446.985701,50.7955818 446.885605,51.0254457 446.699713,51.2040831 L446.646163,51.2479803 L435.76062,61.7097143 C435.357854,62.0967619 434.704841,62.0967619 434.302075,61.7097143 C433.899308,61.3226668 433.899308,60.6951387 434.302075,60.3080911 L444.451352,50.5617773 L434.302075,40.6919089 C433.899308,40.3048613 433.899308,39.6773332 434.302075,39.2902857 C434.704841,38.9032381 435.357854,38.9032381 435.76062,39.2902857 Z"
                                              id="right"
                                            ></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                  <span className="2xl:text-xl lg:text-lg text-base">
                                    Previous
                                  </span>
                                </div>
                              </a>
                            </div>
                            <div className="">
                              <ul className="flex items-center text-sm font-medium -space-x-px">
                                <li className="flex item-center">
                                  <input
                                    className="inline-flex items-center justify-center leading-5 px-2 text-center h-[40px] w-[80px] text-xl bg-white border border-slate-200 theme-color shadow-sm"
                                    type="text"
                                    name="name"
                                    value="1"
                                  />
                                </li>

                                <li className="flex item-center">
                                  <span className="inline-flex items-center justify-center leading-5 px-2 py-2 text-xl mx-4 theme-color">
                                    Of
                                  </span>
                                </li>
                                <li className="flex item-center">
                                  <a
                                    className="inline-flex items-center justify-center leading-5 px-2 py-2 text-xl text-[#C00000] hover:text-[#aa0000]"
                                    href="#0"
                                  >
                                    <span className="w-5">10</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="ml-2">
                              <a
                                href="#/"
                                className="btn bg-white border-slate-200 theme-color hover:text-[#C00000] py-4 pagination-btn"
                              >
                                <div className="flex items-center justify-between">
                                  <span className="2xl:text-xl lg:text-lg text-base">
                                    Next
                                  </span>
                                  <span className="ml-4">
                                    <svg
                                      width="17px"
                                      height="17px"
                                      viewBox="-5 0 23 23"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="icons"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <g
                                          id="ui-gambling-website-lined-icnos-casinoshunter"
                                          transform="translate(-484.000000, -159.000000)"
                                        >
                                          <g
                                            id="square-filled"
                                            transform="translate(50.000000, 120.000000)"
                                          >
                                            <path
                                              d="M435.76062,39.2902857 L446.638635,49.7417043 L446.699713,49.7959169 C446.885605,49.9745543 446.985701,50.2044182 447,50.4382227 L447,50.5617773 C446.985701,50.7955818 446.885605,51.0254457 446.699713,51.2040831 L446.646163,51.2479803 L435.76062,61.7097143 C435.357854,62.0967619 434.704841,62.0967619 434.302075,61.7097143 C433.899308,61.3226668 433.899308,60.6951387 434.302075,60.3080911 L444.451352,50.5617773 L434.302075,40.6919089 C433.899308,40.3048613 433.899308,39.6773332 434.302075,39.2902857 C434.704841,38.9032381 435.357854,38.9032381 435.76062,39.2902857 Z"
                                              id="right"
                                            ></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                  </span>
                                </div>
                              </a>
                            </div>
                          </nav>
                        </div>
                      </div>
                    </nav>
                  </div> */}
                </div>
              </div>
            </div>
          </main>
          {/* <div className="flex items-center justify-between pt-8 mx-4">
            <button className="text-lg font-bold btn btn-gray text-white">
              Back
            </button>
            <button className="text-lg font-bold btn btn-red text-white">
              Save
            </button>
          </div> */}
        </div>
      </>
    );
  }
}
