import {
    GetAllCyberMembersList,
    GetAllCyberMembersContactList,
    GetCyberConnectContactRequestDetailsById,
    GenerateMembershipReport,
    GetMemberReportList,
    DownloadReport,
    SaveClarificationDetails
} from "../ApiEndPoints";
import { axiosGet, axiosPost, axiosPostAuthorize, axiosPostFileAuthorizeblob } from "../AxiosRequests";

export default class CyberConnectMemberServices {
    async getAllCyberMembersList(request) {
        return axiosPost(GetAllCyberMembersList, request);
    }
    async getAllCyberMembersContactList(request) {
        return axiosPost(GetAllCyberMembersContactList, request);
    }

    async getCyberConnectContactRequestDetailsById(request) {
        return axiosGet(GetCyberConnectContactRequestDetailsById, request);
    }

    async generateMembershipReport(request) {
        return axiosPost(GenerateMembershipReport, request);
    }

    async getMemberReportList(request) {
        return axiosPost(GetMemberReportList, request);
    }
    async downloadReport(request) {
        return axiosPostFileAuthorizeblob(DownloadReport, request);
    }
    async saveClarificationDetails(request) {
        return axiosPostAuthorize(SaveClarificationDetails, request)
    }
}