import React from 'react'
import TinyEceEditor from '../../components/ckEditor/TinyMCEEditor';
import ImageCropperWithUpload from '../../components/ImageUpload/UploadImage';
import MultiSelectDropdown from '../../components/dropdown/MultiSelectDropdown';
import ValidationText from "../../utils/validation/ValidationText"
import PageLoader from '../../components/common/pageLoader/PageLoader';
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader';

function OrganisationProfile(props) {
    return (
        <>
            {props.isLoading ? <PageLoader /> :
                <div className="w-full 2xl:px-8 lg:px-4 px-4 mt-5">
                    <div className="grid grid-cols-12 lg:gap-6 gap-3">
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <label className="text-[#757575] big-bullet-points font-bold inline-block mobile-14">
                                    Company Logo<span className="text-[#C00000]">*</span>
                                </label>
                                <p className="mb-2 font-normal  text-base theme-color">
                                    Dimensions 250px by 100px, PNG format
                                </p>
                                <div className='lg:w-[350px] lg:mx-0 mx-auto'>
                                    <ImageCropperWithUpload
                                        key="Main"
                                        height={200}
                                        width={400}
                                        isCheckValidation={true}
                                        MaxFileSize={2097152}
                                        MaxFileSizeMessage={"2MB"}
                                        onSaveImage={props.onSaveImage}
                                        aspactRatio={400 / 200}
                                        imageIdentity="CorporateMembers"
                                        intitImage={props.value.companyLogo}
                                        uploadBtn={"Upload Image"}
                                    />
                                    {/* <ValidationText error={props.validationMessages.error.companyLogo} /> */}
                                </div>
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <div className='orgprofile-codes'>
                                    <label className="text-[#848484] big-bullet-points font-bold  inline-block mobile-14">
                                        Company Description<span className="text-[#C00000]">*</span>
                                    </label>
                                    <div className='ml-1 text-[#848484] xl:text-lg'> Suggested pointers you may include in your description:</div>
                                    <ol className='list-decimal text-[#848484] xl:text-lg pl-8 '>
                                        <li>Your incorporation date and journey</li>
                                        <li>Describe the key markets served</li>
                                        <li> What is your competitive advantage/unique selling point</li>
                                    </ol>
                                </div>
                                {/* <div className='organisationProfile-min-h'>
                                    <div className='ml-1 text-[#757575] xl:text-lg'> Suggested pointers you may include in your description:</div>
                                    <ol className='list-decimal text-[#757575] xl:text-lg pl-8'>
                                        <li>Your incorporation date and journey</li>
                                        <li>Describe the key markets served</li>
                                        <li> What is your competitive advantage/unique selling point</li>
                                    </ol>
                                </div> */}
                                <div className="ck-editor-sec small-editor-t w-full mt-3 white-background tiny-font-default">
                                    <TinyEceEditor
                                        key={"fullPageCKEditor"}
                                        onTinyEceEditorChange={(e) => props.onTinyEceEditorChange(e, "companyDescription")}
                                        data={props.value.companyDescription}
                                        onBlur={() => props.onblur("companyDescription")}
                                        isMenubarNotRequired={true}
                                        isToolbarNotRequired={true}
                                    />
                                    <ValidationText error={props.validationMessages.error.companyDescription} />

                                    <div>
                                    </div>
                                </div>
                                <div className='float-right mt-3 bg-[silver]'>
                                    {props.countCompanyDescription} / 1000
                                </div>
                            </div>

                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <div className='orgprofile-codes prod-description'>
                                    <label className="text-[#848484] big-bullet-points font-bold inline-block mobile-14">
                                        Product Description<span className="text-[#C00000]">*</span>
                                    </label>
                                    <div className='ml-1 text-[#848484] xl:text-lg'> Suggested pointer to cover in your description:</div>
                                    <ol className='pl-8 text-[#848484] xl:text-lg list-none'>
                                        <li>1. Describe how your technology addresses some of the
                                            market gaps</li>

                                    </ol>
                                </div>
                                {/* <div className='organisationProfile-min-h'>
                                    <div className='ml-1 text-[#757575] xl:text-lg'> Suggested pointer to cover in your description:</div>
                                    <ol className='list-none ml-2 text-[#757575] xl:text-lg'>
                                        <li>a. Describe how your technology addresses some of the
                                            market gaps</li>
                                    </ol>
                                </div> */}
                                <div className="ck-editor-sec small-editor-t w-full mt-3 white-background tiny-font-default">
                                    <TinyEceEditor
                                        key={"fullPageCKEditor"}
                                        onTinyEceEditorChange={(e) => props.onTinyEceEditorChange(e, "productDescription")}
                                        data={props.value.productDescription}
                                        onBlur={() => props.onblur("productDescription")}
                                        isMenubarNotRequired={true}
                                        isToolbarNotRequired={true}
                                    />
                                    <div>
                                        <ValidationText error={props.validationMessages.error.productDescription} />
                                    </div>
                                </div>
                                <div className='float-right mt-3 bg-[silver]'>
                                    {props.countProductDescription} / 1000
                                </div>
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <label className="text-[#757575] big-bullet-points font-bold mb-2 inline-block mobile-14">
                                    Geographical Markets Served<span className="text-[#C00000]">*</span>
                                </label>
                                <MultiSelectDropdown
                                    drpIdentity="GeographicalMarketsServed"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={props.GeographicalMarketsServed}
                                    onChange={props.onDropdownChange}
                                    allowSelectAll={true}
                                    value={props.value.selectGeographicalMarketsServed}
                                />
                                <ValidationText error={props.validationMessages.error.selectGeographicalMarketsServed} />

                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <label className="text-[#757575] big-bullet-points font-bold mb-2 inline-block mobile-14">
                                    <div className='flex items-center'>
                                        <span>
                                            Solution Categorisation<span className="text-[#C00000]">*</span>
                                        </span>
                                    </div>
                                </label>
                                <MultiSelectDropdown
                                    drpIdentity="SolutionCategorisation"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={props.SolutionCategorisationdata}
                                    onChange={props.onDropdownChange}
                                    allowSelectAll={true}
                                    value={props.value.selectSolutionCategorisation}
                                />
                                <ValidationText error={props.validationMessages.error.selectSolutionCategorisation} />
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <label className="text-[#757575] big-bullet-points font-bold mb-2 inline-block mobile-14">
                                    Company Website<span className="text-[#C00000]">*</span>
                                </label>
                                <input
                                    className='outline-none appearance-none w-full custom-input'
                                    type="text"
                                    name={"companyWebsite"}
                                    value={props.value.companyWebsite}
                                    onChange={(e) => props.onchange(e)}
                                    onBlur={() => props.onblur("companyWebsite")}
                                ></input>
                                <ValidationText error={props.validationMessages.error.companyWebsite} />
                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <label className="text-[#757575] big-bullet-points font-bold mb-2 inline-block mobile-14">
                                    LinkedIn
                                </label>
                                <input
                                    className='outline-none appearance-none w-full custom-input'
                                    type="text"
                                    name={"LinkedIn"}
                                    value={props.value.LinkedIn}
                                    onChange={(e) => props.onchange(e)}
                                ></input>
                                <ValidationText error={props.validationMessages.error.LinkedIn} />

                            </div>
                        </div>
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 mb-0 input-sec-texts'>
                            <div className="flield-sec mt-2">
                                <label className="text-[#757575] big-bullet-points font-bold mb-2 inline-block mobile-14">
                                    <div className='flex items-center'>
                                        <span>
                                            Credentials relevant to Cybersecurity Industry and any Global Certification<span className="text-[#C00000]">*</span>
                                        </span>
                                    </div>
                                </label>
                                <MultiSelectDropdown
                                    drpIdentity="GlobalCertification"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={props.GlobalCertification}
                                    onChange={props.onDropdownChange}
                                    allowSelectAll={true}
                                    value={props.value.selectGlobalCertification}
                                />
                                <ValidationText error={props.validationMessages.error.selectGlobalCertification} />
                            </div>
                            {props.isOthers === true ?
                                <div className="flield-sec mt-2 ">
                                    <label className="text-[#848484] big-bullet-points font-bold mb-2 inline-block mobile-14">
                                        Others
                                    </label>
                                    <input
                                        className='w-full custom-input mt-5'
                                        type="text"
                                        name={"others"}
                                        value={props.value.others}
                                        onChange={(e) => props.onchange(e)}
                                    ></input>
                                    {/* <ValidationText error={props.validationMessages.error.others} /> */}
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="grid grid-cols-12 lg:gap-6 gap-3">
                        <div className='xl:col-span-6 lg:col-span-6 col-span-12 mb-0 input-sec-texts'>
                            <div className="mt-2">
                                <label className="text-[#757575] big-bullet-points font-bold inline-block mobile-14">
                                    Company Information (e.g., use cases and brochures)
                                </label>
                                <div className='text-[#757575] xl:text-lg'>(Upload up to one file in PDF format)</div>


                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 lg:gap-6 gap-3 items-center mt-1 mb-5">
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2">
                            <div className="flex">
                                {props.value.companyInformation ?
                                    <>
                                        <img
                                            className="pagination-arrow left  md:w-[20px] w-3 ml-2"
                                            src="/checked-icon.svg" alt=""
                                        ></img>
                                        <div className='text-[#757575] xl:text-lg ml-2'>
                                            {/* Company Information (e.g., use cases and brochures).pdf */}
                                            {props.value.companyInformation}
                                        </div>
                                    </>
                                    : ""}

                                {props.value.companyInformation ?
                                    < div
                                        className='text-[#cd0000] xl:text-lg ml-5 underline cursor-pointer view_pdf'
                                        onClick={() => props.downloadPdf()}
                                    >
                                        View PDF
                                    </div>
                                    : null}  </div>
                            <label
                                htmlFor={`Upload_File`}
                                className="btn btn-lightblue text-white font-bold whitespace-nowrap 2xl:text-lg lg:text-base w-[200px] mt-5 cursor-pointer"
                            >
                                Update File
                            </label>
                            <input
                                className="sr-only"
                                // key={`FileQuestion${key}`}
                                id={`Upload_File`}
                                type="file"
                                onChange={(event) => props.onFileChange(event, "Upload_PDF")}
                            />
                        </div>
                        <div className="xl:col-span-6 lg:col-span-6 col-span-12 lg:mb-2 flex lg:justify-end justify-center">
                            {props.isBtnLoading === "ProfileInfo" ?
                                <ButtonLoader loaderBtnClass="w-[200px]" /> :
                                <button
                                    className="btn btn-lightblue text-white font-bold whitespace-nowrap 2xl:text-lg lg:text-base w-[200px]"
                                    onClick={() => props.updateDashboardProfileInfo()}
                                // disabled={this.state.accountDetails.termsAndCondition === true ? false : true}
                                >
                                    Update
                                </button>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default OrganisationProfile
