import React, { Component } from 'react'
import CyberConenctContactRequestModal from '../../components/cyberConenctDetailModal/CyberConenctContactRequestModal';
import Table from '../../components/table/Table';
import CyberConnectMemberServices from '../../services/axiosServices/apiServices/CyberConnectMemberServices';
import SwalServices from '../../services/swalServices/SwalServices';
import moment from "moment";

export default class CyberConnectContactRequest extends Component {
    constructor(props) {
        super(props);
        this.cyberConnectMemberServices = new CyberConnectMemberServices();
        this.swalServices = new SwalServices();
        this.state = {
            cyberConenctContactRequest: false,
            companyDescriptionModal: false,
            solutionCategoryModel: false,
            connectNowModel: false,
            totalResultes: 0,
            totalCount: 0,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                dateFrom: null,
            },
            cyberConnectContactRequestList: [],
            getCyberConnectContactRequestDetails: {},
            isLoading: false
        }
    }

    componentDidMount() {
        this.getAllCyberMembersContactList();
    }

    getAllCyberMembersContactList = () => {
        let pagination = { ...this.state.pagination }
        this.setState({ isLoading: true });
        this.cyberConnectMemberServices.getAllCyberMembersContactList(pagination).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent.itemList;
                details.map((key, index) => {
                    key.createdAt = moment(key.createdAt).format(
                        "DD MMM YYYY"
                    );
                });
                this.setState({ cyberConnectContactRequestList: details })
                this.setState({
                    totalResultes: response.responseItem.responseContent.totalCount,
                });
            } else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }

    handleChange(event) {
        const value = event.target.value;
        const name = event.target.name;
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ pagination: { ...detail } }, () => {
            this.getAllCyberMembersContactList();
        });
    }

    searchByCompanyName = (value, identity) => {
        let detail = this.state.pagination;
        if (identity === "CompanyName") {
            detail.pageNo = 1;
            detail.pageSize = 10;
            detail.searchText = value;
            this.setState({ pagination: { ...detail } });
        }
        this.getAllCyberMembersContactList();
    }

    actions = (element, index, value) => {
        return element !== "cyberConnectRequestsId" ? null : (
            <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt break-all text-center">
                <button
                    className="flex items-center justify-center"
                    onClick={(event) => this.getCyberConnectContactRequestDetailsById(event, value)}
                >
                    <span className="text-[#8DA4B5] font-bold underline mr-8 2xl:text-[15px]">
                        Details
                    </span>
                </button>
            </td>
        );
    };

    getCyberConnectContactRequestDetailsById = (event, value) => {
        this.setState({ cyberConenctContactRequest: true });
        const id = value
        event.stopPropagation();
        this.cyberConnectMemberServices.getCyberConnectContactRequestDetailsById(id).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                let details = response.responseItem.responseContent;
                this.setState({ getCyberConnectContactRequestDetails: details })
            } else {
                this.swalServices.Error(response.message);
            }
        })
    }

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.getAllCyberMembersContactList();
    };

    render() {
        return (
            <>
                <div className=' bg-[#e1e7ee] h-full'>
                    <div className="main-body-section">
                        <div className="custom-card shadow-lg">
                            <div className="filter-info grid grid-cols-12 gap-6">
                                <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        for="default"
                                    >
                                        Company Name
                                    </label>
                                    <input
                                        id="eventName"
                                        name="eventName"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="text"
                                        onChange={(e) => this.searchByCompanyName(e.target.value, 'CompanyName')}
                                    />
                                </div>
                                <div className="xl:col-span-3 lg:col-span-3 col-span-12">
                                    <label
                                        className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                                        for="default"
                                    >
                                        Date
                                    </label>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={moment(
                                            this.state.pagination.dateFrom
                                        ).format("yyyy-MM-DD")}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </div>
                                <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                                    <div className="flex items-end justify-end h-full pb-1">
                                        <button
                                            className="btn btn-red text-white"
                                        //   onClick={() => this.getEntryDetailList()}
                                        >
                                            <span className="text-xl text-white font-semibold tracking-normal">
                                                Search
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cyber-connect-account-table left-table mt-10 text-xl">
                            <Table
                                columns={[
                                    { name: "createdAt", title: "Date and Time" },
                                    { name: "name", title: "Request Company Name" },
                                    { name: "companyName", title: "Target Company Name" },
                                    { name: "organization", title: "Request Company Contact Name" },
                                    { name: "emailAddress", title: "Request Company Contact Email" },
                                    { name: "cyberConnectRequestsId", title: "Action" },
                                    // { name: "cyberConnectContactRequestID", title: "" },
                                ]}
                                rows={this.state.cyberConnectContactRequestList}
                                isLoading={this.state.isLoading}
                                sortingColumns={[""]}
                                pagination={this.state.pagination}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalResultes}
                                setPagination={this.setPagination.bind(this)}
                                customScope={[
                                    {
                                        column: "cyberConnectRequestsId",
                                        renderTableData: this.actions.bind(this),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <CyberConenctContactRequestModal
                    cyberConenctContactRequest={this.state.cyberConenctContactRequest}
                    setOpenModal={() => { this.setState({ cyberConenctContactRequest: false }) }}
                    onClose={(e) => {
                        e.stopPropagation();
                        this.setState({ cyberConenctContactRequest: false });
                    }}
                    getCyberConnectContactRequestDetails={this.state.getCyberConnectContactRequestDetails}
                    cyberConnectContactRequestList={this.state.cyberConnectContactRequestList}
                />
            </>
        )
    }
}
