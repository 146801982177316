import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';
import Table from '../../components/table/Table';
import moment from "moment";
import { encryptionData } from '../../../src/utils/Encryption';
import CyberConnectMemberServices from '../../services/axiosServices/apiServices/CyberConnectMemberServices';
import SwalServices from '../../services/swalServices/SwalServices';

export default class CyberConnectAccountApproval extends Component {
  constructor(props) {
    super(props);
    this.cyberConnectMemberServices = new CyberConnectMemberServices();
    this.swalServices = new SwalServices();
    this.state = {
      redirect: '',
      totalResultes: 0,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        dateFrom: null,
      },
      cyberConnectAccountList: [],
      searchByCompanyName: "",
      isLoading: false
    }
  }

  componentDidMount() {
    this.getAllCyberMembersList();
  }

  getAllCyberMembersList = () => {
    let pagination = { ...this.state.pagination }
    this.setState({ isLoading: true });
    this.cyberConnectMemberServices.getAllCyberMembersList(pagination).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        details.map((key, index) => {
          key.createdAt = moment(key.createdAt).format(
            "DD MMM YYYY"
          );
        });
        this.setState({ cyberConnectAccountList: details });
        this.setState({
          totalResultes: response.responseItem.responseContent.totalCount,
        });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }

  viewCyberConnectAccount = (value) => {
    let id = encryptionData(value)
    this.setState({ redirect: "/ViewCyberConnectAccount?CyberConnectMemberId=" + id });
  }

  actions = (element, index, value) => {
    return element !== "cyberConnectMemberId" ? null : (
      <td className="whitespace-nowrap tble-td-txt break-all text-left">
        <button
          className="flex items-center justify-center"
          onClick={(e) => {
            e.preventDefault();
            this.viewCyberConnectAccount(value)
          }}
        >
          <span className="text-[#8DA4B5] font-bold underline 2xl:text-[15px] ml-5">
            View
          </span>
        </button>
      </td>
    );
  };

  searchByCompanyName = (value, identity) => {
    let detail = this.state.pagination;
    if (identity === "CompanyName") {
      detail.pageNo = 1;
      detail.pageSize = 10;
      detail.searchText = value;
      this.setState({ pagination: { ...detail } });
    }
    this.getAllCyberMembersList();
  }

  handleChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.pagination;
    let fulldate = (detail[name] = value === "" ? null : value);
    let date = new Date(fulldate); //1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM
    let startLimit = new Date("1/1/1753 12:00:00 AM");
    //let endLimit = new Date("12/31/9999 11:59:59 PM");
    detail[name] = value;
    this.setState({ pagination: { ...detail } }, () => {
      this.getAllCyberMembersList();
    });
  }

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getAllCyberMembersList();
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <>
        <div className=' bg-[#e1e7ee] h-full'>
          <div className="main-body-section">
            <div className="custom-card shadow-lg">
              <div className="filter-info grid grid-cols-12 gap-6">
                <div className="filter-types xl:col-span-4 lg:col-span-4 col-span-12">
                  <label
                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                    for="default"
                  >
                    Company Name
                  </label>
                  <input
                    id="eventName"
                    name="eventName"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="text"
                    onChange={(e) => this.searchByCompanyName(e.target.value, 'CompanyName')}
                  />
                </div>
                <div className="xl:col-span-3 lg:col-span-3 col-span-12">
                  <label
                    className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                    for="default"
                  >
                    Date
                  </label>
                  <input
                    id="default"
                    name="dateFrom"
                    className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                    type="date"
                    value={moment(
                      this.state.pagination.dateFrom
                    ).format("yyyy-MM-DD")}
                    onChange={(e) => this.handleChange(e)}
                  />
                </div>
                <div className="xl:col-span-5 lg:col-span-5 col-span-12">
                  <div className="flex items-end justify-end h-full pb-1">
                    <button
                      className="btn btn-red text-white"
                      onClick={() => this.getAllCyberMembersList()}
                    >
                      <span className="text-xl text-white font-semibold tracking-normal">
                        Search
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="cyber-connect-account-table left-table mt-10">
              <Table
                columns={[
                  { name: "companyName", title: "Company Name" },
                  { name: "createdAt", title: "Date of Request" },
                  { name: "accountStatus", title: "Status" },
                  { name: "cyberConnectMemberId", title: "Action" },
                  //{ name: "cyberConnectAccountId", title: "" },
                ]}
                rows={this.state.cyberConnectAccountList}
                isLoading={this.state.isLoading}
                sortingColumns={[""]}
                pagination={this.state.pagination}
                totalResultes={this.state.totalResultes}
                totalCount={this.state.totalResultes}
                setPagination={this.setPagination.bind(this)}
                customScope={[
                  {
                    column: "cyberConnectMemberId",
                    renderTableData: this.actions.bind(this),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}
