//This set to use Production Mode or DevelopmentMode
export const IsProdMode = false;

export const IsEncryption = true;

export const IsQueryParamsEncryption = true;


// AESKey for encryption and decryption
export const AESKeyValue = "azR2N3F4a3pycXFxd2M2dG5ya3E4MjBrbmYzZzM0YWU=";

// AESIV for encryption and decryption
export const AESIVKey = "enZjZmptNTEybWhvb2U4Mg==";

export const IsCookieEncryption = true;

// Web URL Path

export const WebURLPath = "http://localhost:3001/pageManagement/";
export const WebPath = "http://localhost:3001/";
