import React, { useState } from "react";
import ImageCropperWithUpload from "../ImageUpload/UploadImage";
import ObjectModal from "./ObjectModal";

export default function ObjectComponent(props) {
  let [modalOpen, setModalOpen] = useState(false);
  let [fields, setFields] = useState([]);

  const getObjectFields = (fieldArr) => {
    setModalOpen(false);
  };

  return (
    <div>
      <div className="List-text-icon">
        <div className="grid grid-cols-12 gap-6 mt-10 mb-10 border border-[#757575]">
          <div className="filter-types col-span-12 font-bold border-b border-[#757575] bg-[#f0f0f0] px-7 py-3 relative">
            <h2 className="filter-lable  font-medium theme-color">Object</h2>
            
            <div className="close-icon-btn absolute top-[50%] right-[10px] translate-y-[-50%]">
              <button
                className="h-7 w-7 flex items-center justify-center text-[#757575] hover:text-[#3a3a3a] rounded-full border-2 border-[#757575] close-button"
                onClick={() => props.removeComponent()}
              >
                <div className="sr-only">Close</div>
                <svg className="w-4 h-4 fill-current">
                  <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                </svg>
              </button>
            </div>
          </div>
          <div className="filter-types col-span-12 p-8">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12 input-sec-texts">
                <div className="flex items-center">
                  <div className="w-[45%] mr-4 pt-1">
                    <h2 className="filter-lable  font-medium theme-color">
                      Component Unique Id :
                    </h2>
                  </div>
                  <div className="w-[55%]">
                    {/* <TextInput /> */}
                    <input
                      id="default"
                      name="componentId"
                      className="w-full outline-none appearance-none form-input rounded-none bg-zinc-100"
                      type="text"
                      value={props.componentDetails.componentId}
                      onChange={(event) =>
                        props.handleChange(event, props.identity)
                      }
                      disabled={true}
                    //onBlur={() => props.validateField("publishDate")}
                    />
                  </div>
                </div>
              </div>
              <div className="xl:col-span-6 lg:col-span-6 sm:col-span-12">
                <div className="flex items-center">
                  <div className="w-[40%] mr-4 pt-1">
                    <h2 className="filter-lable  font-medium theme-color">
                      Component Name :
                    </h2>
                  </div>
                  <div className="w-[60%]">
                    
                    <input
                      id="default"
                      name="componentName"
                      className={`w-full outline-none appearance-none form-input rounded-none ${(props.componentDetails.componentName) ? 'bg-zinc-100' : ''}`}
                      type="text"
                      value={props.componentDetails.componentName}
                      onChange={(event) =>
                        props.handleChange(event, props.identity)
                      }
                      disabled={(props.componentDetails.componentName) ? true : false}
                    />
                  </div>
                </div>
              </div>
              {props &&
                props.componentDetails.componentContent.map((value, index) => {
                  return (
                    <>
                      <div
                        className="2xl:col-span-4 lg:col-span-4 col-span-12 mt-10 input-sec-texts"
                        key={"ObjectSec_" + index}
                      >
                        <label
                          className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                          for="default"
                        >
                          Column name
                        </label>
                        <input
                          id="fieldName"
                          name="fieldName"
                          className="w-full outline-none appearance-none form-input rounded-none"
                          type="text"
                          value={value.fieldName}
                          onChange={(event) =>
                            props.handleChangeInObject(
                              event.target.name,
                              event.target.value,
                              index
                            )
                          }
                        //onBlur={() => props.validateField("publishDate")}
                        />
                      </div>
                      {value.fieldType === "Text" ? (
                        <div
                          className="2xl:col-span-8 lg:col-span-8 col-span-12 mt-10"
                          key={"ObjectSecTextInput_" + index}
                        >
                          <label
                            className="filter-lable block text-xl font-medium  mb-1 theme-color min-h-30"
                            for="default"
                          >
                            Value
                          </label>
                          <input
                            id="value"
                            name="value"
                            className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                            type="text"
                            onChange={(event) =>
                              props.handleChangeInObject(
                                event.target.name,
                                event.target.value,
                                index
                              )
                            }
                            value={value.value}
                          />
                        </div>
                      ) : (
                        <div
                          className="2xl:col-span-8 lg:col-span-8 col-span-12 mt-10"
                          key={"ObjectSecImageCropper_" + index}
                        >
                          <div className="grid grid-cols-12 gap-6">
                            <div className="2xl:col-span-6 lg:col-span-6 col-span-12">
                              <div className="object-img-part">
                                <ImageCropperWithUpload
                                  key={"ObjectCompImage_" + index}
                                  keyId={"ObjectCompImage_" + index}
                                  height={354}
                                  width={550}
                                  isCheckValidation={true}
                                  MaxFileSize={2097152}
                                  MaxFileSizeMessage={"2MB"}
                                  onSaveImage={(file, id, imageIdentity) =>
                                    props.saveMainImage(
                                      file,
                                      id,
                                      imageIdentity,
                                      props.identity
                                    )
                                  }
                                  imageIdentity={
                                    "\\PageManagement\\ObjectComponent"
                                  }
                                  intitImage={value.value}
                                  aspactRatio={550 / 354}
                                  uploadBtn={"Upload Image"}
                                  dimensionText={
                                    "(Ratio: 550 X 354 Maximum Size: 2MB)"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              <div className="2xl:col-span-12 lg:col-span-12 col-span-12 ">
                <div className="flex justify-end">
                  <button
                    className=" px-7 py-3 btn-red text-xl border text-white"
                    onClick={(e) => {
                      e.stopPropagation();
                      setModalOpen(true);
                    }}
                  >
                    <div className="flex items-end font-semibold">
                      <span className="mr-3">
                        <img src="/plus-round-icon.svg" alt="" />
                      </span>
                      <span className="">Add Object</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ObjectModal
        setAddeditModalOpen={modalOpen}
        componentContent={props.componentDetails.componentContent}
        setModalOpen={() => setModalOpen(!modalOpen)}
        onClose={(e) => {
          e.stopPropagation();
          setModalOpen(false);
        }}
        getObjectFields={getObjectFields.bind(this)}
        addNewFieldInObject={() => props.addNewFieldInObject()}
        removeFieldInObject={(fieldIndex) =>
          props.removeFieldInObject(fieldIndex)
        }
        handleChangeInObject={(name, value, fieldIndex) =>
          props.handleChangeInObject(name, value, fieldIndex)
        }
      />
    </div>
  );
}
