import React, { Component } from "react";
import DashboardServices from "../../services/axiosServices/apiServices/DashboardServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import CorporateMember from "../../components/dashboard/CorporateMember";
import LatestEnquiry from "../../components/dashboard/LatestEnquiry";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.dashboardServices = new DashboardServices();
    this.swalServices = new SwalServices();
    this.state = {
      inboxList: [],
      isLoading: false,
      route: null,
      entries: "",
      dashboardCounts: {},
      roleId: 0,
    };
  }

  GetInboxListForDashboard = () => {
    this.dashboardServices.GetInboxListForDashboard().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ inboxList: response.responseItem.responseContent });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getDashboardCounts = () => {
    this.setState({ isLoading: true });
    this.dashboardServices.getDashboardCounts().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({ dashboardCounts: response.responseItem.responseContent });
      } else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  };

  componentDidMount() {
    let cookies = getAuthProps();
    if (cookies && cookies.roleId > 0) {
      this.setState({ roleId: cookies.roleId })
    }
    this.GetInboxListForDashboard();
    this.getDashboardCounts()
  }

  render() {
    return (
      <div className="main-body-section h-[100%] justify-between bg-[#e1e7ee]">
        {/* Corporate Member Start */}
        <CorporateMember 
        dashboardCounts = {this.state.dashboardCounts}
        />
        {/* Corporate Member Ends */}
        {/* Latest Enquiry Start  */}
        <LatestEnquiry
          inboxList={this.state.inboxList}
        />
        {/* Latest Enquiry End  */}
      </div>
    );
  }
}
