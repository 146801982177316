import React, { Component } from "react";
import Table from "../../components/table/Table";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import PagesManagementServices from "../../services/axiosServices/apiServices/PagesManagementServices";
import SwalServices from "../../services/swalServices/SwalServices";
import CreateButton from "../../components/UIComponent/Buttons/CreateButton";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import { Navigate } from "react-router-dom";
import ActionEditButton from "../../components/UIComponent/ActionTableComponent/ActionEditButton";
import DropdownSelect from "../../components/dropdown/Dropdown";
import ActionDeleteButton from "../../components/UIComponent/ActionTableComponent/ActionDeleteButton";
import { CommonSuccessMessages, SuccessMessage } from "../../utils/Messages";
import { encryptAES } from "../../utils/Encryption";

export default class ViewPages extends Component {
  constructor(props) {
    super(props);
    this.pagesManagementServices = new PagesManagementServices();
    this.CommonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "",
        orderFlag: 0,
        searchText: "",
        status: 0,
        filterByMainModule: 0,
        filterBySubModule: 0,
        filterBySubPage: 0,
        searchByPageTitle: "",
      },
      totalResultes: "",
      getResourcePagesList: [],
      getPagesIds: [],
      isLoading: false,
      redirect: null,
      mainModules: [],
      subModules: [],
      subPages: [],
    };
  }

  componentDidMount() {
    this.getAllDropdownsForViewPages();
    // this.getPagesDetailsList();
  }

  getPagesDetailsList = (pagination = this.state.pagination) => {
    this.setState({ isLoading: true });
    this.pagesManagementServices
      .getPagesDetailsList(pagination)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          details.forEach((element, index) => {
            if (element.pageName === null || element.pageName === "") {
              element.pageName = "-";
            }
          });
          this.setState({
            getResourcePagesList: details,
            totalResultes: response.responseItem.responseContent.totalCount,
          });
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  getAllDropdownsForViewPages = () => {
    this.setState({ isLoading: true });
    this.pagesManagementServices
      .getAllDropdownsForViewPages()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent;
          var mainModulesArr = details.filter(
            (drp) => drp.dropdownName === "MainModules"
          );
          var subModulesArr = details.filter(
            (drp) => drp.dropdownName === "SubModules"
          );
          var subPagesArr = details.filter(
            (drp) => drp.dropdownName === "SubPages"
          );
          this.setState({
            mainModules: mainModulesArr,
            subModules: subModulesArr,
            subPages: subPagesArr,
          });
          this.getPagesDetailsList();
        } else {
          this.getPagesDetailsList();
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
  };

  onDelete = (value) => {
    let request = {
      id: value,
      deletedBy: 115,
      appId: 15,
    };
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_Question.replace("{0}", "Page"),
        CommonSuccessMessages.Confirm_Delete.replace("{0}", "Page"),
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          this.pagesManagementServices
            .deleteStandAlonePageById(request)
            .then((response) => {
              if (response.statusCode === 200) {
                this.swalServices.Success(
                  SuccessMessage.Delete_Success.replace("{0}", "Page")
                );
                this.getPagesDetailsList();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
        }
      });
  };

  setPagination = (newPagination) => {
    this.setState({ pagination: newPagination });
    this.getPagesDetailsList();
  };

  onEdit = (value, index) => {
    let editPageDetails = [...this.state.getResourcePagesList];
    if (editPageDetails[index].pageName === "-") {
      editPageDetails[index].pageName = "MainPage";
    }
    if (editPageDetails[index].pagesDetailsId > 0) {
      this.setState({
        redirect: "/EditPages/" + encryptAES(editPageDetails[index].pagesDetailsId),
      });
    }
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = { ...this.state.pagination };
    if (drpIdentity === "MainModules") {
      detail["filterByMainModule"] = id;
      detail["filterBySubPage"] = 0;
      detail["pageNo"] = 1;
    } else if (drpIdentity === "SubModules") {
      detail["filterBySubModule"] = id;
      detail["filterBySubPage"] = 0;
      detail["pageNo"] = 1;
    } else if (drpIdentity === "SubPages") {
      detail["filterBySubPage"] = id;
      detail["filterByMainModule"] = 0;
      detail["filterBySubModule"] = 0;
      detail["pageNo"] = 1;
    }
    this.setState({ pagination: { ...detail } }, () => {
      this.getPagesDetailsList();
    });
  };

  actions = (element, index, value) => {
    return element !== "pagesDetailsId" ? null : (
      <ActionContainer>
        <ActionEditButton
          value={value}
          id={index}
          index={index}
          onActionClick={this.onEdit.bind(this)}
          text="Edit"
        />
        {this.state.getResourcePagesList[index].pageTypeId === 3 ? (
          <ActionDeleteButton
            value={value}
            id={index}
            index={index}
            onActionClick={this.onDelete}
            text="Delete"
          />
        ) : null}
      </ActionContainer>
    );
  };

  searchByName = (value) => {
    let detail = this.state.pagination;
    // if (value.length > 2) {
    detail.pageNo = 1;
    detail.pageSize = 10;
    detail.searchByPageTitle = value;
    this.setState({ pagination: { ...detail } }, () =>
      this.getPagesDetailsList()
    );
    // }
  };

  render() {
    if (this.state.redirect != null) {
      return (
        <Navigate
          to={this.state.redirect}
          state={this.state.getResourcePagesList}
        />
      );
    }
    return (
      <div className="main-body-section">
        <div className="px-6 pt-10 pb-12 bg-white">
          <div className="grid grid-cols-12 gap-6 w-full">
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
              <h2 className="text-xl theme-color mb-1">Filter by Main Menu</h2>
              <DropdownSelect
                drpIdentity={"MainModules"}
                optionArray={this.state.mainModules}
                setFilterParameters={this.setFilterParameters}
                value={this.state.pagination.filterByMainModule}
              />
            </div>
            {/* <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                            <h2 className="text-xl theme-color mb-1">
                                Filter by Sub Module
                            </h2>
                            <DropdownSelect
                                drpIdentity={"SubModules"}
                                optionArray={this.state.subModules}
                                setFilterParameters={this.setFilterParameters}
                                value={this.state.pagination.filterBySubModule}
                            />
                        </div> */}
            {/* <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
                            <h2 className="text-xl theme-color mb-1">
                                Filter by Sub Page
                            </h2>
                            <DropdownSelect
                                drpIdentity={"SubPages"}
                                optionArray={this.state.subPages}
                                setFilterParameters={this.setFilterParameters}
                                value={this.state.pagination.filterBySubPage}
                            />
                        </div> */}
            <div className="2xl:col-span-4 lg:col-span-4 col-span-12">
              <h2 className="text-xl theme-color mb-1">
                Search By Sub Menu / Page Title
              </h2>
              <input
                className="w-full"
                // placeholder="Meta Title"
                type="text"
                id="searchByPageTitle"
                name="searchByPageTitle"
                onChange={(e) => this.searchByName(e.target.value)}
                value={this.state.pagination.searchByPageTitle}
              />
            </div>
          </div>
        </div>

        <div className="w-full mt-6">
          <div className="flex items-center justify-end">
            <div className="create-button">
              <CreateButton redirect="/CreatePages" text="Create New" />
            </div>
          </div>
        </div>

        <main className="pb-10">
          <div className="w-full mx-auto">
            <div className="relative">
              <div className="view-page-table-section">
                <div className=" mt-6">
                  <Table
                    columns={[
                      { name: "pagesMainModulesName", title: "Main Menu" },
                      { name: "pagesSubModulesName", title: "Sub Menu" },
                      { name: "pageName", title: "Page Title" },
                      { name: "PageTypeName", title: "Page Type" },
                      { name: "pagesDetailsId", title: "Action" },
                    ]}
                    pagination={this.state.pagination}
                    rows={this.state.getResourcePagesList}
                    sortingColumns={["status"]}
                    isLoading={this.state.isLoading}
                    totalResultes={this.state.totalResultes}
                    totalCount={this.state.totalResultes}
                    setPagination={this.setPagination.bind(this)}
                    customScope={[
                      {
                        column: "pagesDetailsId",
                        renderTableData: this.actions.bind(this),
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
